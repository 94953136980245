exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-form-detail-index-js": () => import("./../../../src/pages/form-detail/index.js" /* webpackChunkName: "component---src-pages-form-detail-index-js" */),
  "component---src-pages-forms-index-js": () => import("./../../../src/pages/forms/index.js" /* webpackChunkName: "component---src-pages-forms-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-templates-contact-index-js": () => import("./../../../src/templates/contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-templates-downloads-index-js": () => import("./../../../src/templates/downloads/index.js" /* webpackChunkName: "component---src-templates-downloads-index-js" */),
  "component---src-templates-general-index-js": () => import("./../../../src/templates/general/index.js" /* webpackChunkName: "component---src-templates-general-index-js" */),
  "component---src-templates-pdp-index-js": () => import("./../../../src/templates/pdp/index.js" /* webpackChunkName: "component---src-templates-pdp-index-js" */),
  "component---src-templates-portfolio-index-js": () => import("./../../../src/templates/portfolio/index.js" /* webpackChunkName: "component---src-templates-portfolio-index-js" */),
  "component---src-templates-project-details-index-js": () => import("./../../../src/templates/project-details/index.js" /* webpackChunkName: "component---src-templates-project-details-index-js" */),
  "component---src-templates-projects-index-js": () => import("./../../../src/templates/projects/index.js" /* webpackChunkName: "component---src-templates-projects-index-js" */)
}

