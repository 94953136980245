import React from 'react';
import PropTypes from 'prop-types';
import { Row, Column } from 'sash-foundation';
import { validateArray } from 'sash-foundation/dist/utils';

import { Image } from '../../';
import './index.scss';

const IconLink = ({ data, className }) => {
	if (!data) return false;

	return (
		<div className={`duratherm-iconLink ${className || ''}`}>
			<Row align='center' noGutters={true}>
				<Column xs={4}>
					<div className='duratherm-iconLink-icon'>
						{
							data.image &&
							validateArray(data.image) &&
							<Image data={data.image[0]} />
						}
					</div>
				</Column>

				{
					data.description &&
					<Column xs={8}>
						<div className='duratherm-iconLink-body' dangerouslySetInnerHTML={{ __html: data.description }} />
					</Column>
				}
			</Row>
		</div>
	);
};

IconLink.propTypes = {
	data: PropTypes.object,
	className: PropTypes.string
};

export default IconLink;
