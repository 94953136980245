import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const InfoWindow = ({ name }) => {
	return (
		<div className='duratherm-infoWindow sf-p--lg'>
			<h3 className='h3__alt'>{name}</h3>
			<p>720 Main Street<br />Vassalboro, ME 04989</p>
		</div>
	);
};

InfoWindow.propTypes = {
	name: PropTypes.string
};

export default InfoWindow;
