// Export common components to utilize grouped imports
// - example: import { Layout, SEO } from '../../components';
import React, { useLayoutEffect, useState } from 'react';

export { default as Layout } from './layout';
export { default as SEO } from './common/seo';
export { default as Breadcrumbs } from './common/breadcrumbs';
export { default as Card } from './common/card';
export { default as IconLink } from './common/icon-link';
export { default as BaseDir } from './common/link';
export { default as Image } from './common/image';
export { default as CTA } from './common/cta';
export { default as LeftNav } from './common/left-nav';
export { default as LoadingOverlay } from './common/loading-overlay';
export { default as PageHero } from './common/page-hero';
export { default as GoogleMap } from './common/google-map/index';
export { default as EloquaForm } from './common/eloqua-form/';

export const useWindowSize = () => {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		const updateSize = () => {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return size;
}
