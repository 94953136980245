import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Row, Column, Container } from 'sash-foundation';
import { validateArray } from 'sash-foundation/dist/utils';
import { MdFileDownload } from 'react-icons/md';
import { initDataLayer } from '../../components/utilities/functions';

import { Layout, SEO, LeftNav, IconLink, Image, CTA, Card, Breadcrumbs } from '../../components';
import './index.scss';

const Downloads = (props) => {
	const pageData = props.data.contentstackDownloads;

	return (
		<Layout>
			<SEO {...pageData.seo} />
			{pageData.breadcrumb &&
					<Breadcrumbs
						data={pageData.breadcrumb}
						currentUrl={pageData.url}
						smallWidth={true}
					/>
				}
			<Container width={1540} fluid={true}>
				<Row>
					<Column className='topHeading' xs={12}>
						{
							pageData.top_content &&
							<h1 className='downloadHeading' dangerouslySetInnerHTML={{ __html: pageData.top_content.heading }} />
						}
					</Column>
					<Column className='topSubheading' xs={12}>
						{
							pageData.top_content &&
							<div dangerouslySetInnerHTML={{ __html: pageData.top_content.subheading }} />
						}
					</Column>
				</Row>
				<Row className='brochureCard'>
					{pageData.brochure &&
						pageData.brochure.map((item, index) => (
							<Column className='teamBioBox' xs='12' lg='fluid'>
								<Row>
									<Column className='sf-display--none sf-display-790--block' xs='5' sm='4' md='4' lg='4'>
										<Image
											data={item.thumbnail[0]}
											className='bioImage'
											loading='eager'
										/>
									</Column>
									<Column className='bioText2' key={index} xs='fluid'>
										<Row noGutters={true}>
											<h3
												dangerouslySetInnerHTML={{ __html: item.heading }}
											/>
										</Row>
										<Row noGutters={true}>
											<div
												className='desc'
												dangerouslySetInnerHTML={{ __html: item.description }}
											/>
										</Row>
										<Row noGutters={true}>
											<CTA data={item.cta[0]} className='downloadBrochure'>
												{item.cta[0].cta_title && <span>{item.cta[0].cta_title}</span>}
												<MdFileDownload />
											</CTA>
										</Row>
									</Column>
								</Row>
							</Column>
						))
					}
				</Row>
					{
						pageData.cad_files.files_heading &&
						<h2 id={pageData.cad_files.anchor_text} dangerouslySetInnerHTML={{ __html: pageData.cad_files.files_heading }} />
					}
					{pageData.cad_files &&
						<div className='tableFull'>
							{
								pageData.cad_files.table_groups &&
								validateArray(pageData.cad_files.table_groups) &&
								<div className='downloadSheetColumns' >
									{ pageData.cad_files.table_groups.map((row, index) => (
										<div className='tableSpacing' xs={12} md={5}>
											<table className='duratherm-downloads-table'>
												<tbody>
													<React.Fragment key={index}>
														{
															row.heading &&
															<tr>
																<td colSpan={4} className='tableHeading'>{row.heading}</td>
															</tr>
														}

														{ row.heading == ' ' &&
															<tr>
																<td colSpan={4} className='tableHeading'></td>
															</tr>
														}

														{
															row.group &&
															validateArray(row.group) &&
															row.group.map((group, index) => (
																<tr key={index}>
																	{row.heading == 'ALL PRODUCTS' &&
																		<td className='tableLeft'>
																			{group.subhead || <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
																		</td>
																	}

																	{row.heading !== 'ALL PRODUCTS' &&
																		<td className='tableLeft'>
																			{group.subhead || <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
																		</td>
																	}

																	{
																		group.sub_category &&
																		validateArray(group.sub_category) &&
																		group.sub_category[0].downloads &&
																		validateArray(group.sub_category[0].downloads) &&
																		group.sub_category[0].downloads.map((cell, index) => (
																			<td key={index}>
																				<p>
																					{
																						cell.link &&
																						validateArray(cell.link) &&
																						<CTA 
																							data={cell} 
																							className='downloadCell'
																							onClick={() => initDataLayer('file-download',  {fileName: cell.cta_title})}
																						>

																							{cell.cta_title && <span>{cell.cta_title}</span>}
																							<MdFileDownload />
																						</CTA>
																					}
																				</p>
																			</td>
																		))
																	}

																	{
																		group.sub_category &&
																		!validateArray(group.sub_category) &&
																		<>
																			<td>-</td>
																			<td>-</td>
																			<td>-</td>
																		</>
																	}
																</tr>
															))
														}
													</React.Fragment>
												</tbody>
											</table>
										</div>
									))
									}
								</div>
							}
						</div>
					}
			</Container>

		</Layout>
	);
};

Downloads.propTypes = {
	data: PropTypes.object
};

export default Downloads;

export const query = graphql`
query($uid: String!) {
	contentstackDownloads(uid: { eq: $uid }) {
		url
		brochure {
			heading
			thumbnail {
				...Image
			}
			description
			cta {
				...CTA
			}
		}

		top_content {
			heading
			subheading
		}

		breadcrumb {
			active_page
			page_links {
				...CTA
			}
		}

		cad_files {
			files_heading
			anchor_text
			table_groups {
			  	heading
			  	group {
					subhead
					sub_category {
				  		downloads {
							...CTA
				  		}
					}
			  	}
			}
		}

		seo {
			title
			keywords
			description
		}
	}
}
`;
