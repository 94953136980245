import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

import './index.scss';

const cookies = new Cookies();

const BrowserMessage = () => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		const isIE = /*@cc_on~@*/false || !!document.documentMode;

		if (isIE && cookies.get('HideMessage') == null) {
			setShow(true);
		}
	}, []);


	const onCloseClick = () => {
		cookies.set('HideMessage', true, { expires: new Date(Date.now() + (60*60*19*1000)) });
		setShow(false);
	};

	return (
		show &&
		<div className='sash-browserMessage'>
			<div className='sash-browserMessage-wrapper'>
				<button className='sash-browserMessage-close' onClick={() => onCloseClick()}>
					<svg stroke='currentColor' fill='currentColor' strokeWidth='0' viewBox='0 0 24 24' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'>
						<path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' />
					</svg>
				</button>

				<h2>Browser Not Supported</h2>
				<p>We no longer support Internet Explorer. For the best website experience, please upgrade to one of these browsers:</p>

				<ul className='sash-browserList'>
					<li>
						<img
							src='https://images.contentstack.io/v3/assets/bltf589e66bcaecd79c/blt5d4edc69d83f2530/5e9dc36df923401150072071/logo-chrome.png?height=64'
							alt='Chrome Logo'
						/>

						Chrome
					</li>

					<li className='sash-browserListItem--middle'>
						<img
							src='https://images.contentstack.io/v3/assets/bltf589e66bcaecd79c/blta355eb956e5e112c/5e9dc36d5661cd12411d5318/logo-firefox.png?height=64'
							alt='Firefox Logo'
						/>

						Firefox
					</li>

					<li>
						<img
							src='https://images.contentstack.io/v3/assets/bltf589e66bcaecd79c/blt0aa0398a04be6a59/5e9dc36dd5a1cb709eee49c8/logo-safari.png?height=64'
							alt='Safari Logo'
						/>

						Safari
					</li>
				</ul>
			</div>
		</div>
	);
};

export default BrowserMessage;
