import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { List } from 'sash-foundation';
import { validateArray } from 'sash-foundation/dist/utils';

import { CTA } from '../../';
import './index.scss';

const LeftNav = ({ heading, links, type = 'Link', activeTab, tabFunction, className }) => {
	return (
		<div className={`duratherm-leftNav ${className || ''}`}>
			{heading && <p className='duratherm-leftNavTitle'>{heading}</p>}

			{
				links &&
				validateArray(links) &&
				<List bullets='none' className={`duratherm-leftNavList duratherm-leftNavList--${type.toLowerCase()}`}>
					{
						links.map((link, index) => (
							<li key={index}>
								{
									(type === 'Link' || type === 'Download') &&
									<CTA data={link} />
								}

								{
									type === 'Tabs' &&
									<button
										className={`duratherm-leftNav-tab ${activeTab === index ? 'duratherm-tab--active' : ''}`}
										onClick={() => tabFunction(index)}
									>
										{link}
									</button>
								}

								{
									type === 'pageRef' &&
									<Link to={link.url}>
										{link.case_studies_homepage.case_study_page ? link.case_studies_homepage.title : link.modular_blocks[1].description.bottom_content.heading}
									</Link>
								}

								{
									type === 'mouseover' &&
									<button
										className={`duratherm-leftNav-tab ${activeTab === index ? 'duratherm-tab--active' : ''}`}
										onMouseOver={() => tabFunction(index)}
									>
										{link.nav_heading}
									</button>
								}
							</li>
						))
					}
				</List>
			}
		</div>
	);
};

LeftNav.propTypes = {
	heading: PropTypes.string,
	links: PropTypes.array,
	type: PropTypes.string,
	activeTab: PropTypes.number,
	tabFunction: PropTypes.func,
	className: PropTypes.string
};

export default LeftNav;
