import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { Container, Row, Column } from 'sash-foundation';
import { Layout, SEO, Image, BaseDir } from '../../components';
import { MdChevronRight } from 'react-icons/md';

import './index.scss';

const Projects = (props) => {
	const data = props.data.contentstackProjectDetailsLanding;

	return (
		<Layout>
			<SEO {...data.seo} />
				{data.project_details &&
					<Container width={1540}>
						<Row>
							{data.heading && 
								<h1
									className='sf-text--left contentHeading'
									dangerouslySetInnerHTML={{ __html: data.heading}}
								/>
							}
						</Row>
						<Row className='projects-mbot'>
							<div
								className='sf-text--left projects_projectDesc'
								dangerouslySetInnerHTML={{ __html: data.description}}
							/>
						</Row>
						<Row className='projects-mbot'>
							{data.project_details.map((item, index) => (
								<Column key={index} className='projects_projectCard' xs={12} lg={6}>
									<Row>
										<Link to={item.url} >
											{item.landing_page.image[0] &&
												<Image
													data={item.landing_page.image[0]}
													className='projects_productCardImage'
													loading='eager'
												/>								
											}
										</Link>
									</Row>
									<Row>
										<Link className='projects_projectHeading' to={item.url}>
											<h2
												className='sf-text--left projects_projectHeading'
												dangerouslySetInnerHTML={{ __html: item.landing_page.heading}}
											/>
										</Link>
									</Row>

									<Row>
										<div
											className='sf-text--left projects_projectsubHeading'
											dangerouslySetInnerHTML={{ __html: item.hero.stats[1]}}
										/>
									</Row>
									<Row>
										<div
											className='sf-text--left projects_projectDesc'
											dangerouslySetInnerHTML={{ __html: item.landing_page.description}}
										/>
									</Row>
									{/* <Row>
										<Link className='learnMoreCta' to={item.url}>
											Learn More
											<MdChevronRight />
										</Link>
									</Row> */}
								</Column>
							))
							}
						</Row>
					</Container>
				}
			<div className='spacer40' />
		</Layout>
	);
};

Projects.propTypes = {
	data: PropTypes.object
};

export default Projects;

export const query = graphql`
	query($uid: String!) {
		contentstackProjectDetailsLanding(uid: { eq: $uid }) {
			url
			title
			heading
			description
			seo {
				title
				description
			}
			project_details {
				id
				hero {
					heading
					description
					subheading
					stats
					gallery_images {
						image {
							url
							title
						}
					}
				}
				landing_page {
					image {
						image {
							url
							title
						}
					}
					heading
					description
				}
				url
			}
		}
	}
`;
