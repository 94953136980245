import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { MdMenu } from 'react-icons/md';
import { FaCaretDown } from 'react-icons/fa';

import { Container, Row, Column, List } from 'sash-foundation';
import BaseDir from '../link';
import { validateArray } from 'sash-foundation/dist/utils';

import './index.scss';
import MobileMenu from './mobile-menu';

const Header = () => {
	const [activeMobile, setActiveMobile] = useState(false);
	const [submenuHeight, setSubmenuHeight] = useState(0);
	const data = useStaticQuery(query).contentstackMenu2021;

	useEffect(() => {
		if (data) {
			const submenu = document.getElementById('submenu-0');
			if (submenu) {
				setSubmenuHeight(submenu.offsetHeight);
			}
		}
	}, [data]);

	const onMouseOver = index => {
		if (index) {
			const submenu = document.getElementById(`submenu-${index}`);
			if (submenu) {
				setSubmenuHeight(submenu.offsetHeight);
			}
		}
	}

	return (
		<header className='bonelli-header'>
			<Container width={1540} fluid={true}>
				<Row
					className='sf-align-items--center sf-align-items-1030--end'
					justify='between'
				>
					<Column xs={7} md='auto'>
						{data.home_icon && data.home_icon.url &&
							<a href='/' className='bonelli-logoLink' title='Duratherm Homepage'>
								<img
									src={data.home_icon.url + '?width=290'}
									srcSet={`${data.home_icon.url + '?width=290'}, ${data.home_icon.url + '?width=580'} 2x`}
									alt='Duratherm'
									className='bonelli-logo'
								/>
							</a>
						}
					</Column>

					<Column
						lg='fluid'
						className='remove-position sf-display--none sf-display-1030--block'
					>
						{data.top_navigation && data.top_navigation.main_menu && validateArray(data.top_navigation.main_menu) &&
							<List
								bullets='none'
								layout='inline'
								className='bonelli-menu sf-justify-content--start sf-align-items--end'
							>
								{data.top_navigation.main_menu.map((item, index) => (
									<li key={index} className='sf-text--center' onMouseOver={() => onMouseOver(index)}>
										{item.has_subgroup &&
											<div className='bonelli-menuLinkWrapper'>
												<div className='sf-display--block headingMargin'>
													{item.heading}
													<FaCaretDown className='bonelli-menu-expandIcon' />
												</div>
												{item.links.cta &&
													<List id={`submenu-${index}`} bullets='none' className='bonelli-submenu'>
														{item.links.cta.map((sub, index) => (
															<li key={index}>
																{index === 0 &&
																	<BaseDir className='firstLink' data={sub} />
																}
																{index !== 0 &&
																	<BaseDir data={sub} />
																}
															</li>
														))}
													</List>
												}
											</div>
										}
										{item.has_subgroup == false &&
											<div className='bonelli-menuLinkWrapper'>
												<div className='sf-display--block headingMargin '>
													<BaseDir className='hoverFix' data={item.links.cta[0]} />
												</div>
											</div>
										}



										{/* {item && <div className='bonelli-submenuBg' style={{ height: submenuHeight }} />} */}
									</li>
								))}
							</List>
						}
					</Column>

					<Column
						xs='auto'
						className='sf-display--none sf-display-1030--block'
					>
						<div className='sf-display--flex globalNav'>
							{data.global_nav.group.map((item, index) => (
								<span key={index}>
									{index === 0 &&
										<BaseDir className='globalNavLinks' data={item.cta[0]}>
											<div className='globalNavLinks' >{item.cta[0].cta_title} <span>&nbsp;</span></div>
										</BaseDir>
									}
									{index !== 0 &&
										<BaseDir className='globalNavLinks' data={item.cta[0]} >
											<div> <span>|&nbsp;</span> {item.cta[0].cta_title} <span>&nbsp;</span> </div>
										</BaseDir>
									}
								</span>
							))}
						</div>
						{data.top_navigation.phone_number &&
							<a className='bonelli-phone' href={`tel:+${data.top_navigation.phone_number}${data.top_navigation.phone_number_1}${data.top_navigation.phone_number_2}`}>
								<div className='sf-display--flex'>
									<div className='bonelli-header-phone'>{data.top_navigation.phone_number}</div>
									<div className='bonelli-header-dash'>{data.top_navigation.dash}</div>
									<div className='bonelli-header-phone'>{data.top_navigation.phone_number_1}</div>
									<div className='bonelli-header-dash'>{data.top_navigation.dash}</div>
									<div className='bonelli-header-phone'>{data.top_navigation.phone_number_2}</div>
								</div>
							</a>
						}
					</Column>

					<Column
						xs='auto'
						className='sf-display-1030--none'
					>
						<button className='bonelli-mobileMenuBtn' onClick={() => setActiveMobile(!activeMobile)}>
							<MdMenu />
						</button>
					</Column>
				</Row>

				<MobileMenu
					data={data.top_navigation.main_menu}
					globalData = {data.global_nav.group}
					active={activeMobile}
					phone_number={data.top_navigation.phone_number}
					phone_number_1={data.top_navigation.phone_number_1}
					phone_number_2={data.top_navigation.phone_number_2}
					dash={data.top_navigation.dash}
				/>
			</Container>


		</header>
	);
};

Header.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object
}

export default Header;

const query = graphql`
{
	contentstackMenu2021 {
		home_icon {
			url
		}
		#top_navigation {
		#	navigation {
		#		cta {
		#			...CTA
		#		}
		#		has_subgroup
		#		subgroup {
		#			reference {
		#				...CTA
		#			}
		#		}
		#	}
		#}
		top_navigation {
			main_menu {
				heading
				has_subgroup
				links {
					cta {
						...CTA
					}
				}
			}
			phone_number_2
			phone_number_1
			phone_number
			dash
		}
		global_nav {
			group {
				home
				cta {
					...CTA
				}
			}
		}
	}
}
`;
