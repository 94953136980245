import React from 'react';
import PropTypes from 'prop-types';
import { Row, Column } from 'sash-foundation';
import { validateArray } from 'sash-foundation/dist/utils';
import { Image } from '../../';

import './index.scss';

const PageHero = ({ data, className, hasContentBg = true }) => {
	return (
		<div className={`duratherm-pageHero ${className || ''}`}>
			<Row noGutters={true} reverse={data.image_position === 'Left'}>
				{
					data.image_position !== 'Text Overlap - With Bar' &&
					<Column xs={4}>
						<div className={`duratherm-pageHero-content ${!hasContentBg ? 'duratherm-pageHero-content--noBg' : ''}`}>
							{data.heading && <h1>{data.heading}</h1> }
							{data.description && <div dangerouslySetInnerHTML={{ __html: data.description }} />}
						</div>
					</Column>
				}

				<Column xs={data.image_position === 'Text Overlap - With Bar' ? 12 : 8}>
					{
						data.image &&
						validateArray(data.image) &&
						((data.link && validateArray(data.link)) &&
						<a href={data.link[0].destination} target='_blank' title={`Link opens ${data.link[0].default_link_title} in a new window`} rel='noopener noreferrer'>
							<Image data={data.image[0]} className='sf-display--block' />
						</a>
						|| <Image data={data.image[0]} className='sf-display--block' />)
					}

					{
						data.video_reference &&
						validateArray(data.video_reference) &&
						<div className='duratherm-pageHero-video'>
							<iframe src={data.video_reference[0].destination} allowFullScreen />
						</div>
					}

					{
						data.image_position === 'Text Overlap - With Bar' &&
						<div className='duratherm-pageHero-content--bar'>
							{data.heading && <h1>{data.heading}</h1> }
							{data.description && <div dangerouslySetInnerHTML={{ __html: data.description }} />}
						</div>
					}

					{
						data.photo_cred && <p className='duratherm-pageHero-credit'>{data.photo_cred}</p>
					}
				</Column>
			</Row>
		</div>
	);
};

PageHero.propTypes = {
	data: PropTypes.object,
	className: PropTypes.string,
	hasContentBg: PropTypes.bool
};

export default PageHero;
