import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { Carousel } from 'react-responsive-carousel';
import { Row, Column, Container } from 'sash-foundation';
import { MdChevronRight } from 'react-icons/md';
import { Layout, SEO, Card, Image, BaseDir } from '../components';

import './index.scss';
import './carousel.scss';


const IndexPage = (props) => {
	const pageData = props.data.contentstackHome2;

	useEffect(() => {
	}, []);

	return (
		<Layout>
			<SEO {...pageData.seo} />
			<Container width={1580} fluid={true}>
				{pageData?.carousel[0]?.reference[0] &&
				<div className='contentPadding2'>
					<Carousel width='100%' swipeable={true} showThumbs={false} showStatus={false} infiniteLoop={true} showArrows={false} autoPlay={true} interval={5000}>
						{pageData.carousel[0] &&
							pageData.carousel.map((item, index) => (
								<div className='height100' key={index}>
									<Image data={item.reference[0]} />
									{item.architect !== '' && item.location !== '' && item.cta[0].destination !== '' &&
										<div className='legend'>
											<Row noGutters={true}>
												<Column lg={9}>
													<p className='legendTitle'>{item.architect}</p>
													<p className='legendSubheading'>{item.location}</p>
												</Column>
												<Column lg={3}>
													<Link className='bonelli_home_carouselCta' to={item.cta[0].destination}>
														<MdChevronRight />
													</Link>
												</Column>
											</Row>
										</div>
									}

								</div>
							))
						}
					</Carousel>
				</div>
				}
			</Container>
			<Container className="noPaddingCont" width={1540} fluid={true}>
				<Row>
					<Column lg='12'>
						<h1 className='centerText2'
							dangerouslySetInnerHTML={{ __html: pageData.top_content.heading }}
						/>
						{pageData.top_content.description !== ' ' &&
							<div
								className='centerText'
								dangerouslySetInnerHTML={{ __html: pageData.top_content.description }}
							/>
						}
					</Column>
				</Row>

				<Row className='contentPadding2'>
					{pageData.features &&
						pageData.features.group.map((item, index) => (
							<Column key={index} className={index % 2 === 0 ? 'featureBox' : 'featureBox2'} xs='12' sm='12' md='fluid'  >
								<Row noGutters={true} className='hoverText1'>
									<h2
										dangerouslySetInnerHTML={{ __html: item.heading }}
									/>
								</Row>
								<Row noGutters={true} className='hoverText2'>
									<div
										dangerouslySetInnerHTML={{ __html: item.description }}
									/>
								</Row>
								<Row noGutters={true} className='hoverText3'>
									<BaseDir className= 'featureBoxCta' data={item.cta[0]}>
										{item.cta[0].cta_title}
										<MdChevronRight />
									</BaseDir>
								</Row>
							</Column>
						))
					}
				</Row>
			</Container>

			{pageData?.quote_callout?.quote &&
				<div className='quoteContainer'>
					<div className='quoteTextLayer'>
						<img className='quoteImg' src='https://images.contentstack.io/v3/assets/blt483bc172c0094ee2/bltd61c6f207925141e/60b0fd27909370737ae4ba3a/Quotes.png' alt='quote icon' />
						<div
							// className='quoteText'
							dangerouslySetInnerHTML={{ __html: pageData.quote_callout.quote}}
						/>
						<div
							className= 'floatRight'
							dangerouslySetInnerHTML={{ __html: pageData.quote_callout.citation}}
						/>
					</div>
				</div>
			}

			{pageData?.project &&
				<Container className='featuredProjectContainer' width={1540} fluid={true}>
					<div className='contentPadding2'>
						<Row className='featuredProjectBox' noGutters={true}>
							{pageData.project.image &&
								<Column xs={12} md={12} lg={12} xl={7}>
									<Image data={pageData.project.image[0]} className= 'featuredProjectBlockImage' />
								</Column>
							}
							<Column className='marginAuto1' xs={12} sm={12} md={12} lg={12} xl={4}>
								<Row noGutters={true}>
									<Column xs={10} sm={11} lg={12}>
											<div
												className='featuredProject'
												dangerouslySetInnerHTML={{ __html: pageData.project.details.subheading}}
											/>
											<div
												className= 'featuredProjectHeading'
												dangerouslySetInnerHTML={{ __html: pageData.project.details.heading}}
											/>
									</Column>
								</Row>
								{pageData?.project?.details?.stats &&
									<>
										<Row className='displayAbove1440'>
											<Column className='' xs={12} sm={12} lg={12}>
												<div
													className= 'featuredProjectLocationSubheading'
													dangerouslySetInnerHTML={{ __html: pageData.project.details.stats[0].stat_heading}}
												/>
											</Column>
											<Column className='' xs={12} sm={12} lg={12}>
												<div
													className= 'featuredProjectLocationSubheading2'
													dangerouslySetInnerHTML={{ __html: pageData.project.details.stats[0].stat_description}}
												/>
											</Column>
											<Column className='' xs={12} sm={12} lg={12}>
												<div
													className= 'featuredProjectLocationSubheading'
													dangerouslySetInnerHTML={{ __html: pageData.project.details.stats[1].stat_heading}}
												/>
											</Column>
											<Column className='' xs={12} sm={12} lg={12}>
												<div
													className= 'featuredProjectLocationSubheading2'
													dangerouslySetInnerHTML={{ __html: pageData.project.details.stats[1].stat_description}}
												/>
											</Column>
											<Column className='' xs={12} sm={12} lg={12}>
												<div
													className= 'featuredProjectLocationSubheading'
													dangerouslySetInnerHTML={{ __html: pageData.project.details.stats[2].stat_heading}}
												/>
											</Column>
											<Column className='' xs={12} sm={12} lg={12}>
												<div
													className= 'featuredProjectLocationSubheading2'
													dangerouslySetInnerHTML={{ __html: pageData.project.details.stats[2].stat_description}}
												/>
											</Column>
											<Column className='' xs={12} sm={12} lg={11}>
												<div
													className= 'featuredProjectLocationDesc'
													dangerouslySetInnerHTML={{ __html: pageData.project.details.description}}
												/>
											</Column>
										</Row>
										<Row className='displayBelow1440'>
											<Column className='displayAbove800' xs={10} sm={6} md={6} lg={6}>
												<div
													className= 'featuredProjectLocationDesc'
													dangerouslySetInnerHTML={{ __html: pageData.project.details.description}}
												/>
											</Column>
											<Column className='' xs={10} sm={12} md={6} lg={6}>
												<div
													className= 'featuredProjectLocationSubheading'
													dangerouslySetInnerHTML={{ __html: pageData.project.details.stats[0].stat_heading}}
												/>
												<div
													className= 'featuredProjectLocationSubheading2'
													dangerouslySetInnerHTML={{ __html: pageData.project.details.stats[0].stat_description}}
												/>
												<div
													className= 'featuredProjectLocationSubheading'
													dangerouslySetInnerHTML={{ __html: pageData.project.details.stats[1].stat_heading}}
												/>
												<div
													className= 'featuredProjectLocationSubheading2'
													dangerouslySetInnerHTML={{ __html: pageData.project.details.stats[1].stat_description}}
												/>
												<div
													className= 'featuredProjectLocationSubheading'
													dangerouslySetInnerHTML={{ __html: pageData.project.details.stats[2].stat_heading}}
												/>
												<div
													className= 'featuredProjectLocationSubheading2'
													dangerouslySetInnerHTML={{ __html: pageData.project.details.stats[2].stat_description}}
												/>
											</Column>
										</Row>
										<Row className=''>
											<Column className='marginTop' xs='12' lg='12'>
												<BaseDir className= 'readMoreHeading' data={pageData.project.details.cta[0]}>
													{pageData.project.details.cta[0].cta_title}
													<MdChevronRight />
												</BaseDir>
											</Column>
											<Column className='marginBot' xs='12' lg='12'>
												<BaseDir className='viewProject' data={ pageData.project.details.cta[1]}>
													{pageData.project.details.cta[1].cta_title}
												</BaseDir>
											</Column>
										</Row>
									</>
								}

							</Column>
						</Row>
					</div>
				</Container>
			}
		</Layout>
	);
};

IndexPage.propTypes = {
	data: PropTypes.object
};

export default IndexPage;

export const query = graphql`
{
	contentstackHome2 {
		url
		carousel {
			cta {
			  default_link_title
			  destination
			  title
			}
			architect
			location
			reference {
				image {
					url
				}
			}
		}
		top_content {
		  heading
		  description
		}
		seo {
		  title
		  keywords
		  description
		}
		title
		quote_callout {
		  quote
		  citation
		}
		project {
		  image {
			...Image
		  }
		  details {
			subheading
			stats {
			  stat_heading
			  stat_description
			}
			heading
			description
			cta {
				...CTA
			}
		  }
		}
		features {
		  group {
			heading
			description
			cta {
				...CTA
			}
		  }
		}
	  }
}
`;
