import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { graphql, useStaticQuery } from "gatsby";
import {
	Container,
	Row,
	Column,
	List,
	TextInput,
	Button,
} from "sash-foundation";
import { BaseDir, Image, EloquaForm } from "../../";
import { validateArray, validateImage } from "sash-foundation/dist/utils";
import { MdChevronRight } from "react-icons/md";

import "./index.scss";

const Footer = () => {
	const data = useStaticQuery(query).contentstackFooter;
	const [values, setValues] = useState({ emailAddress: "" });
	const [success, setSuccess] = useState(false);
	const [submitDisabled, setSubmitDisabled] = useState(true);
	const [errorText, setErrorText] = useState("");

	const emailRegex =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	const onSubmit = (e) => {
		e.preventDefault();
		setSubmitDisabled(true);

		let errorText = "";

		if (values.emailAddress == "") {
			errorText = "Email Address is a required field";
		} else if (values.emailAddress.match(emailRegex) === null) {
			errorText = "Please enter a valid Email Address";
		}

		if (errorText == "") {
			const formData = new FormData(e.currentTarget);

			axios
				.post("https://tracking.durathermwindow.com/e/f2", formData)
				.then((res) => {
					if (res.status === 200) {
						setSuccess(true);
						setSubmitDisabled(true);
						setErrorText("");

						//Data Layer
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({
							event: "email-signup",
							success: "true",
						});
					}
				})
				.catch
				// err => console.log(err)
				();
		}

		if (errorText) {
			setSuccess(false);
			setErrorText(errorText);
		}
	};

	return (
		<footer className="bonelli-footer">
			<div className="bonelli-footerTop">
				<Container fluid={true}>
					<Row>
						<Column xs={12} xl={12}>
							{data.footer_columns &&
								validateArray(data.footer_columns) && (
									<Row>
										{data.footer_columns.map(
											(col, index) => (
												<Column
													key={index}
													xs={12}
													md={2}
												>
													<List bullets="none">
														{col.cta &&
															validateArray(
																col.cta
															) &&
															col.cta.map(
																(
																	link,
																	index
																) => (
																	<li
																		key={
																			index
																		}
																	>
																		<BaseDir
																			data={
																				link
																			}
																		/>
																	</li>
																)
															)}
													</List>
												</Column>
											)
										)}
										<Column xs={12} md={2}></Column>
										{data.subscribe && (
											<Column
												id="sign-up"
												className="reverseRow"
												xs={12}
												lg={10}
												xl={4}
											>
												<EloquaForm
													elqFormName="FooterSignup"
													onFormSubmit={onSubmit}
												>
													<Row noGutters={true}>
														<Column xs="12">
															<div
																className="subscribeHeader"
																dangerouslySetInnerHTML={{
																	__html: data
																		.subscribe
																		.heading,
																}}
															/>
														</Column>
														<Column
															xs="fluid"
															xl={9}
														>
															<TextInput
																type="email"
																id="emailAddress"
																aria-required="true"
																required={true}
																onChange={(
																	e
																) => {
																	setValues({
																		...values,
																		emailAddress:
																			e
																				.target
																				.value,
																	});
																	setErrorText(
																		""
																	);
																	setSubmitDisabled(
																		false
																	);
																}}
																className="subscribeTextInput"
																autocomplete="off"
															/>
														</Column>
														<Column xs="fluid">
															<Button
																id="submitSubscribe"
																size="md"
																data-gtm-linktype="button"
																data-gtm-linktext="Email Subscribe"
																className="bonelli-footer-submitBtn"
																type="submit"
																disabled={
																	submitDisabled
																}
															>
																<span className="sf-display--none sf-display-790--block">
																	Sign Up
																</span>
																<span className="sf-display-790--none">
																	<MdChevronRight />
																</span>
															</Button>
														</Column>
													</Row>
													{errorText && (
														<p className="footerSub_Error">
															{errorText}
														</p>
													)}
													{success && (
														<p className="footerSub_Success">
															Almost done! Check
															your inbox to verify
															your e-mail address.
														</p>
													)}
												</EloquaForm>
											</Column>
										)}
									</Row>
								)}
						</Column>
					</Row>
				</Container>
			</div>

			<div className="bonelli-copyright">
				<Container fluid={true}>
					<Row
						align="center"
						justify="between"
						className="bonelli-copyrightRow"
					>
						<Column xs={12} md="auto">
							<Row noGutters={true} align="center">
								{data.copyright && data.copyright.logo && (
									<Column
										xs="auto"
										className="sf-display--none sf-display-790--block"
									>
										<Image data={data.copyright.logo} />
										<img
											src={
												data.copyright.logo.url +
												"?width=43&height=64"
											}
											srcSet={`${
												data.copyright.logo.url +
												"?width=43&height=64"
											}, ${
												data.copyright.logo.url +
												"?width=43&height=64"
											} x2`}
											alt="Duratherm"
											className="durathermLogo"
										/>
									</Column>
								)}
								<Column xs="fluid">
									<Row>
										<span
											style={{
												fontSize: "1rem",
												color: "#9f9d9d",
												padding: "0 3px 0 10px",
											}}
										>
											&#169; {new Date().getFullYear()}
										</span>
										<span className="bonelli-copyrightBusiness">
											{data.copyright.business}
										</span>
									</Row>

									{data.copyright.phone && (
										<p className="bonelli-copyrightPhone sf-display--none sf-display-1030--block">
											{data.copyright.phone}
										</p>
									)}
								</Column>
							</Row>
						</Column>

						<Column xs={12} md="auto">
							{data.social &&
								data.social.group &&
								validateArray(data.social.group) && (
									<List
										bullets="none"
										layout="inline"
										className="sf-justify-content--around"
									>
										{data.social.group.map(
											(item, index) => (
												<li key={index}>
													<a
														href={item.url}
														target="_blank"
														rel="noopener noreferrer"
														title="Link opens website in a new window"
														className="bonelli-socialLink"
													>
														{item.reference &&
															validateImage(
																item.reference
															) && (
																<Image
																	data={
																		item
																			.reference[0]
																	}
																/>
															)}
													</a>
												</li>
											)
										)}
									</List>
								)}
						</Column>
					</Row>
				</Container>
			</div>
		</footer>
	);
};

Footer.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
};

export default Footer;

export const query = graphql`
	{
		contentstackFooter {
			footer_columns {
				cta {
					...CTA
				}
			}
			subscribe {
				heading
			}
			copyright {
				logo {
					url
				}
				business
				phone
			}
			social {
				group {
					reference {
						...Image
					}
					url
				}
			}
		}
	}
`;
