import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate, Link } from 'gatsby';
import axios from 'axios';
import queryString from 'query-string';

import { Row, Column, Alert, List } from 'sash-foundation';
import { Layout, SEO, LoadingOverlay } from '../../components';
import { isLoggedIn } from '../../components/utilities/private-route';
import { getSessionStorage, logout } from '../../components/utilities/auth';
import './index.scss';

const FormDetail = (props) => {
	const userToken = getSessionStorage('__p_app_token__');
	const [form, setForm] = useState({});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	useEffect(() => {
		if (isLoggedIn()) {
			const params = queryString.parse(props.location.search);
			fetchFormData(params.id);
		} else {
			navigate('/login/');
		}
	}, []);


	function fetchFormData(id) {
		axios({
			method: "GET",
			url: `${process.env.GATSBY_PELLA_API}/duratherm-window-service/api/v1/quotes/${id}`,
			headers: { Authorization: `Bearer ${userToken}` }
		})
			.then(res => {
				if (res.status === 200) {
					setLoading(false);
					setForm(res.data.data);
				}
			})
			.catch(err => {
				if (err) {
					setLoading(false);
					setError('Something went wrong. Please try again later.');
				}
			});
	}

	const onDownloadClick = (filePath) => {
		setLoading(true);
		const url = `${process.env.GATSBY_PELLA_API}/duratherm-window-service/api/v1/files${filePath}`;

		axios({
			method: "GET",
			url: url,
			responseType: 'blob',
			headers: { Authorization: `Bearer ${userToken}` }
		})
			.then(res => {
				if (res.status === 200) {
					let blob = new Blob([res.data]);
					let link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = filePath.split('/')[2];
					link.click();
					setLoading(false);
				}
			})
			.catch(err => {
				if (err) {
					setLoading(false);
					setError('Something went wrong. Please try again later.');
				}
			});
	};

	return (
		<Layout>
			<SEO title='Form Details' />

			<div className='duratherm-forms-box'>
				<List bullets='none' layout='inline' className='duratherm-loggedin-header'>
					<li><Link to='/forms/'>All Form Submissions</Link></li>
					<li><button onClick={() => logout(() => { navigate('/login/'); })}>Logout</button></li>
				</List>

				<h1>Form Details</h1>
				{
					error &&
					<Alert type='error' onClose={() => setError('')}>{error}</Alert>
				}

				{
					loading &&
					<LoadingOverlay fixed={false} />
				}

				{
					!loading &&
					form &&
					Object.keys(form).length > 0 &&
					<>
						<h3>Project Information</h3>

						<Row className='sf-mb--xl'>
							<Column xs={3}>
								<p className='duratherm-formDetail-heading'>Project Name</p>
								<p>{form.projectName || '-'}</p>
							</Column>

							<Column xs={3}>
								<p className='duratherm-formDetail-heading'>Project Location</p>
								<p>{form.projectLocation || '-'}</p>
							</Column>

							<Column xs={3}>
								<p className='duratherm-formDetail-heading'>Project Stage</p>
								<p>{form.projectStage || '-'}</p>
							</Column>

							<Column xs={3}>
								<p className='duratherm-formDetail-heading'>Drawing File</p>
								{form.filePath && <p><button onClick={() => onDownloadClick(form.filePath)}>Download File</button></p> || <p>-</p>}
							</Column>
						</Row>

						<h3>Customer Contact Information</h3>
						<Row>
							<Column xs={4}>
								<p className='duratherm-formDetail-heading'>Customer Name</p>
								<p>{form.name || '-'}</p>
							</Column>

							<Column xs={4}>
								<p className='duratherm-formDetail-heading'>Email</p>
								<p>{form.email || '-'}</p>
							</Column>

							<Column xs={4}>
								<p className='duratherm-formDetail-heading'>Phone</p>
								<p>{form.phone || '-'}</p>
							</Column>

							<Column xs={4}>
								<p className='duratherm-formDetail-heading'>Customer Type</p>
								<p>{form.customerType || '-'}</p>
							</Column>

							<Column xs={4}>
								<p className='duratherm-formDetail-heading'>Firm Name</p>
								<p>{form.firmName || '-'}</p>
							</Column>

							<Column xs={4}>
								<p className='duratherm-formDetail-heading'>Location</p>
								<p>
									{form.address || ''}<br />
									{form.city || ''} {form.state || ''}, {form.zip || ''}
								</p>
							</Column>

							<Column xs={4}>
								<p className='duratherm-formDetail-heading'>How did you find us?</p>
								<p>{form.referral || '-'}</p>
							</Column>
						</Row>
					</>
				}
			</div>
		</Layout>
	);
};

FormDetail.propTypes = {
	location: PropTypes.object
};

export default FormDetail;
