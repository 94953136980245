import axios from "axios";

export const useCdnQuery = (
	contentTypeUid = null,
	query = null,
	other = null,
	skip = null,
	limit = null
) => {
	const CDNURL = `https://cdn.contentstack.io/v3/content_types/${contentTypeUid}/entries?${other || ""}`;
	let headers = {
		api_key: `${process.env.GATSBY_CONTENTSTACK_API_KEY || ""}`,
		authtoken: `${process.env.GATSBY_CONTENTSTACK_DELIVERY_TOKEN || ""}`
	};

	let params = {
		environment: `${process.env.GATSBY_CONTENTSTACK_ENVIRONMENT || "development"}`,
		skip,
		limit,
		query
	};

	skip ? params.skip=skip : '';
	limit ? params.limit = limit : '';

	return axios.get(CDNURL, { params, headers });
};
