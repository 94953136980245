import axios from 'axios';
import sessionStorage from 'sessionstorage';

// set local storage
export const setSessionStorage = (key, value) => {
	if (typeof window !== 'undefined') {
		if (typeof value === 'string') {
			return sessionStorage.setItem(key, value);
		} else {
			return sessionStorage.setItem(key, JSON.stringify(value));
		}
	}
};

// get local storage
export const getSessionStorage = (key) => {
	if (typeof window !== 'undefined') {
		const token = sessionStorage.getItem(key);
		return token;
	}
};

// remove local storage
export const removeSessionStorage = (key) => {
	if (typeof window !== 'undefined') {
		return sessionStorage.removeItem(key);
	}
};




// Auth Functions
export const checkAuth = () => {
	if (window !== 'undefined') {
		const tokenChecked = getSessionStorage('__p_app_token__');

		if (tokenChecked) {
			if (getSessionStorage('__p_app_user__')) {
				// return JSON.parse(getSessionStorage('__p_app_user__'));
				return true;
			} else {
				return false;
			}
		}
	}
};

export const checkExpire = () => {
	// const refreshToken = getSessionStorage('__p_app_refresh_token__');
	const tokenExpire = getSessionStorage('__p_app_expire_date__');
	const nowTime = new Date();
	const tokenTime = new Date(JSON.parse(tokenExpire));

	// for dev
	//nowTime.setDate(nowTime.getDate() + 1);

	if (nowTime > tokenTime) {
		console.log('nowTime ', nowTime);
		console.log('tokenTime ', tokenTime);
		return true;
	} else {
		return false;
	}
};

export const refresh = async (refreshToken) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	const body = { refresh_token: refreshToken };

	try {
		const res = await axios.post(
			`${process.env.GATSBY_PELLA_API}/account/v1.1/auth/refresh`,
			body,
			config
		);

		console.log('RES ---> ', res.data);

		if (res.data.success === true) {
			setSessionStorage('__p_app_token__', res.data.Token.access_token);
			setSessionStorage(
				'__p_app_refresh_token__',
				res.data.Token.refresh_token
			);
			setSessionStorage(
				'__p_app_expire_date__',
				res.data.Token.expire_date
			);
			setAuthToken(res.data.Token.access_token);
			try {
				const userRes = await axios.get(
					`${process.env.GATSBY_PELLA_API}/account/v1.1/user`
				);
				console.log('USER RES ---> ', userRes);
				if (userRes.data.success === true) {
					setSessionStorage('__p_app_user__', userRes.data.User);
					//next();
					return true;
				}
				if (userRes.data.success === false) {
					//next();
					return false;
				}
			} catch (err) {
				console.log('ERROR ', err);
				//next();
				return false;
			}
		}
		if (res.data.success === false) {
			console.log('SUCCESS FALSE ', res.data);
			//next();
			return false;
		}
	} catch (error) {
		console.log('ERROR ', error);
		//next();
		return false;
		// if (error) {
		// 	next();
		// 	// return false;
		// }
	}
};

export const loadUser = async (token, next) => {
	setAuthToken(token);

	try {
		const res = await axios.get(`${process.env.GATSBY_PELLA_API}/account/v1.1/user`);

		if (res.data.success === true) {
			console.log('LOAD USER SUCCESS ---> ', res.data);
			let returnCompany = '';
			let returnPartyID = '';
			let returnPhone = '';

			if (res.data.User.OraclePartyID !== undefined) {
				returnPartyID = res.data.User.OraclePartyID;
			}

			if (res.data.User.company !== undefined) {
				returnCompany = res.data.User.company;
			}
			if (res.data.User.phoneNumbers !== undefined) {
				returnPhone = res.data.User.phoneNumbers[0].value;
			}

			let accountInfo = {
				userID: res.data.User.id,
				firstName: res.data.User.name.firstname,
				lastName: res.data.User.name.lastname,
				email: res.data.User.emails[0].value,
				phone: returnPhone,
				partyID: returnPartyID,
				company: returnCompany,
			};
			sessionStorage.setItem('accountInfo', accountInfo);
			setSessionStorage('__p_app_user__', res.data.User);
			next();
		}

		if (res.data.success === false) {
			next(res.data.error);
		}
	} catch (err) {
		console.log(err);
		next(err);
	}
};

export const authenticate = (response, next) => {
	setSessionStorage('__p_app_token__', response.access_token);
	setSessionStorage('__p_app_refresh_token__', response.refresh_token);
	setSessionStorage('__p_app_expire_date__', response.expire_date);

	next();
};

export const setAuthToken = (token) => {
	if (token) {
		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	} else {
		delete axios.defaults.headers.common['Authorization'];
	}
};

//logout
export const logout = (next) => {
	removeSessionStorage('__p_app_token__');
	removeSessionStorage('__p_app_refresh_token__');
	removeSessionStorage('__p_app_expire_date__');
	removeSessionStorage('__p_app_user__');
	removeSessionStorage('__p_app_display_install__');
	sessionStorage.clear();
	next();
};
