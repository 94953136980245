import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'sash-foundation';

import BrowserMessage from '../common/browser-message';
import Header from '../common/header';
import Footer from '../common/footer';

import './index.scss';

const Layout = ({ children, className, isHome, activePage = '', ...other }) => {
	const skipTo = useRef(null);

	const onSkipContentClick = () => {
		skipTo.current.focus();
	};

	const pageClass = activePage.includes('/doors/') ? 'duratherm-ap--doors' : activePage.includes('/windows/') ? 'duratherm-ap--windows' : activePage.includes('/node/') ? 'duratherm-ap--news' : activePage.includes('/whyduratherm/') ? 'duratherm-ap--whyDuratherm' : activePage.includes('/casestudy/') ? 'duratherm-ap--caseStudy' : '';

	return (
		<div className={`${pageClass} ${className || ''}`}>
			<button onClick={() => onSkipContentClick()} className='layout-skipLink'>Skip to </button>

			<main id='content' {...other} ref={skipTo}>
				<Header/>
				<div className='spacer' />
				{children}
				<Footer />
			</main>

			<BrowserMessage />
		</div>
	);
};

Layout.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	isHome: PropTypes.bool,
	activePage: PropTypes.string
};

export default Layout;
