import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Container, Row, Column, List } from 'sash-foundation';
import { Layout, SEO, Breadcrumbs, Image, BaseDir } from '../../components';
import { FaCaretRight } from 'react-icons/fa';
import { MdChevronRight } from 'react-icons/md';
import ProjectSlider from '../../components/page/pdp/project-slider';

import './index.scss';
import { validateArray, validateImage, validateLink } from 'sash-foundation/dist/utils';

const General = (props) => {
	const data = props.data.contentstackGeneralPage;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout>
			<SEO {...data.seo} />

			{data.breadcrumb && data.hero_?.hero_image == '' &&
			<Container width={1540}>
					<Breadcrumbs
						data={data.breadcrumb}
						currentUrl={data.url}
						smallWidth={true}
					/>
			</Container>

			}

			<div className='general-product-hero'>
				{data.breadcrumb && data.hero_?.hero_image && validateImage(data.hero_.hero_image) &&
					<Breadcrumbs
						data={data.breadcrumb}
						currentUrl={data.url}
						smallWidth={true}
						className='paddingLeft60'
					/>
				}

				{data.hero_?.hero_image && validateImage(data.hero_.hero_image) &&
					<Container className='productBackground' width={1540}>
						<Row align='center'>
							{data.hero_.hero_image && validateImage(data.hero_.hero_image) &&
								<Column xs={12} lg={5}>
									<Image
										data={data.hero_.hero_image[0]}
										className='product-heroImage'
										loading='eager'
										// className='product-heroImage sf-display--block sf-mb--lg sf-mb-1030--0'
									/>
								</Column>
							}
							{data.hero_?.quote?.quote &&
								<Column xs={12} lg='fluid'>
									{data.hero_.heading &&
										<h1 className='productHeading'>
											{data.hero_.heading}
										</h1>
									}
									{data.hero_.is_quote &&
										<>
											<img className='imgQuote' src='https://images.contentstack.io/v3/assets/blt483bc172c0094ee2/bltd61c6f207925141e/60b0fd27909370737ae4ba3a/Quotes.png' alt='quote icon' />
											<div
												className='quoteText'
												dangerouslySetInnerHTML={{ __html: data.hero_.quote.quote}}
											/>
											<div
												className= 'floatRight1'
												dangerouslySetInnerHTML={{ __html: data.hero_.quote.citation}}
											/>
										</>
									}
									{!data.hero_.is_quote &&
										<>
											<div
												className='quoteText2'
												dangerouslySetInnerHTML={{ __html: data.hero_.quote.quote}}
											/>
											{data.hero_.cta && validateArray(data.hero_.cta) &&
												<BaseDir className='quoteTextCta' data={data.hero_.cta[0]}> 
													{data.hero_.cta[0].cta_title}
													<MdChevronRight />
												</BaseDir>
											}
										</>
									}
								</Column>
							}
						</Row>
					</Container>
				}
			</div>

			<Container className='contentPadding' width={1540}>
				<Row>
					<Column xs={12} md={8}>
						<Row>
							{data.modular_blocks && validateArray(data.modular_blocks) && data.modular_blocks.map((block, index) => {
								if (block.content_only != null) {
									return (
										<Column key={index} xs={12}>
											<Row className={'contentOnlyHeading'}>
												{block.content_only.heading && block.content_only.is_h1 == true &&
													<h1 className='sf-text--left contentHeading'>{block.content_only.heading}</h1>
												}
												{block.content_only.heading && !block.content_only.is_h1 &&
													<h2 className='sf-text--left contentHeading'>{block.content_only.heading}</h2>
												}
											</Row>
											<Row>
												{block.content_only.description &&
													<div
														className='sf-text--left contentSpacing'
														dangerouslySetInnerHTML={{ __html: block.content_only.description}}
													/>
												}
											</Row>
										</Column>
									);
								}

								if (block.our_team != null) {
									return (
										<Column className='bioNoPadding' key={index} xs={12}>
											<Row noGutters={true}>
												{block.our_team.team_bios &&
													block.our_team.team_bios.map((item, index) => (
														<Column key={index} className='teamBioBox' xs='12' lg='fluid'>
															<Row className='marginAuto' noGutters={true}>
																<Column className='marginAuto' xs='5' sm='4' md='4' lg='5'>
																	<Image
																		data={item.image[0]}
																		className='bioImage1'
																		loading='eager'
																	/>
																</Column>
																<Column className='bioText' key={index} xs='fluid'>
																	<Row noGutters={true}>
																		<h2
																			className="paddingLeftTeam"
																			dangerouslySetInnerHTML={{ __html: item.heading }}
																		/>
																	</Row>
																	<Row noGutters={true}>
																		<p
																			className='subText'
																			dangerouslySetInnerHTML={{ __html: item.subheading }}
																		/>
																	</Row>
																	<Row noGutters={true}>
																		<div
																			className='desc'
																			dangerouslySetInnerHTML={{ __html: item.description }}
																		/>
																	</Row>
																</Column>
															</Row>
														</Column>
													))
												}
											</Row>
										</Column>
									);
								}

								if (block.Left_and_Right != null) {
									return (
										<Column key={index} xs={12} lg='fluid'>
											<Row className={'contentOnlyHeading'}>
												{block.Left_and_Right.heading &&
													<>
														<Column className='paddingNone' xs={12}>
															<h2 className='sf-text--left'>{block.Left_and_Right.heading}</h2>
														</Column>
													</>
												}
												{block.Left_and_Right &&
													block.Left_and_Right.rows.map((item, index) => (
														<React.Fragment key={index}>
															<Column className='historyBlock' xs='12' md='7'>
																<Row className='historyHeading'>
																	<div
																		className='historyHeading'
																		dangerouslySetInnerHTML={{ __html: item.left.heading }}
																	/>
																</Row>
																<Row>
																	<div
																		className='historyDesc'
																		dangerouslySetInnerHTML={{ __html: item.left.rich_text_editor }}
																	/>
																</Row>
															</Column>
															<Column className='' xs='12' md='7'>
																<Row className='historyHeading'>
																	<div
																		className='historyHeading'
																		dangerouslySetInnerHTML={{ __html: item.right.heading }}
																	/>
																</Row>
																<Row>
																	<div
																		className='historyDesc'
																		dangerouslySetInnerHTML={{ __html: item.right.description }}
																	/>
																</Row>
															</Column>
														</React.Fragment>
													))
												}
											</Row>
										</Column>
									);
								}

								if (block.columns != null && block.columns.column && validateArray(block.columns.column)) {
									return (
										<div className='general-columns' key={index}>
											<Row>
												{block.columns.column.map((col, index) => (
													<Column key={index} xs={col.column_mobile} md={col.column_desktop}>
														{col.image && validateImage(col.image) &&
															<Image data={col.image[0]} />
														}
														{col.heading &&
															<h2>{col.heading}</h2>
														}
														{col.subheading &&
															<h3>{col.subheading}</h3>
														}
														{col.description &&
															<div dangerouslySetInnerHTML={{ __html: col.description }} />
														}
														{col.cta && validateArray(col.cta) &&
															<BaseDir className='columnsCta' data={col.cta[0]}> 
																{col.cta[0].cta_title}
																<MdChevronRight />
															</BaseDir>
														}
													</Column>
												))}
											</Row>
										</div>
									);
								}

								if (block.projects != null && block.projects.tag && validateArray(block.projects.tag)) {
									return <ProjectSlider key={index} data={block.projects} />;
								}
							})}
						</Row>
					</Column>

					<Column className='rightNav' xs='fluid'>
						<Row>
							<Column xs={12}>
								{data.right_nav.heading &&
									<div className='sf-text--left sideNavHeading'>{data.right_nav.heading}</div>
								}
							</Column>
							<Column xs={12}>
								{data.right_nav && validateArray(data.right_nav.cta) &&
									<List bullets='none' className='pdp-heroDownloads'>
										{data.right_nav.cta.map((item, index) => (
											<li key={index}>
												<a
													href={item.link[0].destination}
													title={item.cta_title}
												>
													<FaCaretRight />
													{item.cta_title && <span>{item.cta_title}</span>}
												</a>
											</li>
										))}
									</List>
								}
							</Column>

						</Row>
					</Column>
				</Row>
			</Container>
			<div className='spacer40' />
		</Layout>
	);
};

General.propTypes = {
	data: PropTypes.object
};

export default General;

export const query = graphql`
	query($uid: String!) {
		contentstackGeneralPage(uid: { eq: $uid }) {
			breadcrumb {
				active_page
			}
			hero_ {
				heading
				hero_image {
					image {
						url
					}
					alt_text
					title
				}
				quote {
					citation
					quote
				}
				is_quote
				cta {
					...CTA
				}
			}
			modular_blocks {
				#Featured_Project {
					#details {
					#	cta {
					#		...CTA
					#	}
					#	description
					#	heading
					#	stats {
					#		stat_description
					#		stat_heading
					#	}
					#	subheading
					#}
					#image {
					#	...Image
					#}
				#}
				Left_and_Right {
					heading
					rows {
					left {
						heading
						rich_text_editor
					}
					right {
						description
						heading
					}
					}
				}
				content_only {
					cta {
						...CTA
					}
					is_h1
					description
					heading
					subheading
				}
				#feature {
				#	cta {
				#		...CTA
				#	}
				#	description
				#	heading
				#	image {
				#		...Image
				#	}
				#	image_position
				#	subheading
				#}
				our_team {
					team_bios {
						description
						heading
						image {
							...Image
						}
						subheading
					}
				}
				columns {
					column {
						column_mobile
						column_desktop
						image {
							...Image
						}
						heading
						subheading
						description
						cta {
							...CTA
						}
					}
				}
				projects {
					heading
					tag {
						tag_name
					}
				}
			}
			other_pages {
				light_background
				resources {
					cta {
						...CTA
					}
					heading
					image {
						...Image
					}
				}
			}
			right_nav {
				cta {
					...CTA
				}
				heading
			}
			seo {
				description
				keywords
				title
			}
			title
			url
		}
	}
`;
