import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './marker.js';

import './index.scss';

const GoogleMap = (props) => {
	const [center, setCenter] = useState({lat: 44.47240, lng: -69.61802 });
	const [zoom, setZoom] = useState(13);
	const [show, setShow] = useState(false);

	const getMapOptions = (maps) => {
		return {
			//disableDefaultUI: true,
			mapTypeControl: true,
			streetViewControl: true,
			styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
		};
	};

	const onChildClickCallback = () => {
		setShow(!show);
	};

	return (
		<div style={{ height: '100vh', width: '100%', 'max-height': '600px' }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key:'AIzaSyD2xuXo_3k50Lf88fy0_8GQ3AnyGDQMUEk' }}
				defaultCenter={center}
				defaultZoom={zoom}
				options={getMapOptions}
				onChildClick={() => onChildClickCallback()}
			>
				<Marker
					lat={44.472590}
					lng={-69.617060}
					name='Vassalboro, Maine Office'
					show={show}
				/>
			</GoogleMapReact>
		</div>
	);
};

GoogleMap.propTypes = {
};

export default GoogleMap;
