import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const LoadingOverlay = ({ fixed = true, className, ...rest }) => {
	return (
		<div className={`duratherm-loader ${!fixed ? 'duratherm-loader--absolute' : ''} ${className || ''}`} {...rest}>
			<img
				src='https://images.contentstack.io/v3/assets/bltf589e66bcaecd79c/bltd92200a13a8e76ab/5f34317e7f3b7908db032ccf/oval.svg'
				height={75}
				alt='Loading...'
			/>
		</div>
	);
};

LoadingOverlay.propTypes = {
	fixed: PropTypes.bool,
	className: PropTypes.string
};

export default LoadingOverlay;
