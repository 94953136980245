import React from 'react';
import PropTypes from 'prop-types';
import { List, Row } from 'sash-foundation';
import BaseDir from '../../link';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { FaCaretUp } from 'react-icons/fa';


import './index.scss';

const MobileMenu = ({ data, active, phone_number, phone_number_1, phone_number_2, dash, globalData }) => {
	return (
		<div className={`bonelli-mobileMenu ${active ? 'bonelli-mobileMenu--active' : ''}`}>
			<Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
				<List bullets='none' className='bonelli-downloadLinks'>
					{data.map((item, index) => (
						<React.Fragment key={index}>
						{item.has_subgroup === false &&
							<div className='singleLink' >
								<BaseDir className='singleLink' data={item.links.cta[0]} />
							</div>
						}
						{item.has_subgroup && item.links.cta &&
							<AccordionItem>
								<AccordionItemHeading className='mobileSubMenuNavHeading'>
									<AccordionItemButton><FaCaretUp className='bonelli-menu-expandIcon' />{item.heading}</AccordionItemButton>
								</AccordionItemHeading>

									<AccordionItemPanel>
											<List bullets='none' className='bonelli-mobileSubMenu'>
												{item.links.cta.map((sub, index) => (
													<li key={index}>
														<BaseDir data={sub} className='bonelli-mobileSubnavLink' />
													</li>
												))}
											</List>
									</AccordionItemPanel>
							</AccordionItem>
						}

						</React.Fragment>
					))}

					{globalData.map((item, index) => (
						<React.Fragment key={index}>
							<div className='globalLinks' >
								<BaseDir className='globalLinks' data={item.cta[0]} />
							</div>
						</React.Fragment>
					))}
				</List>
			</Accordion>
			{phone_number &&
				<a className='bonelli-phone' href={`tel:+${phone_number}${phone_number_1}${phone_number_2}`}>
					<Row className='mobilePhone'>
							<div className='bonelli-header-phone'>{phone_number}</div>
							<span className='bonelli-header-dash'>{dash}</span>
							<div className='bonelli-header-phone'>{phone_number_1}</div>
							<span className='bonelli-header-dash'>{dash}</span>
							<div className='bonelli-header-phone'>{phone_number_2}</div>
					</Row>
				</a>

			}
		</div>
	);
};

MobileMenu.propTypes = {
	data: PropTypes.array,
	globalData: PropTypes.array,
	active: PropTypes.bool,
	phone_number: PropTypes.string,
	phone_number_1: PropTypes.string,
	phone_number_2: PropTypes.string,
	dash: PropTypes.string,
}

export default MobileMenu;
