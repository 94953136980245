import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { getSessionStorage } from '../utilities/auth';

export const isLoggedIn = () => {
	const userInfo = JSON.parse(getSessionStorage('__p_app_user__'));

	if (userInfo != null) {
		return true;
	}
};


const PrivateRoute = ({ component: Component, ...rest }) => {
	if (!isLoggedIn()) {
		// If the user is not logged in, redirect to the login page.
		navigate(`/login/`);
		return null;
	}

	return <Component {...rest} />;
};

PrivateRoute.propTypes = {
	component: PropTypes.func
};

export default PrivateRoute;
