import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ data, className, loading = 'lazy', ...other }) => {
	if (!data || (data && !data.image))
		return null;

	return (
		<img
			src={data.image && data.image.url}
			alt={data.alt_text || ''}
			className={className || ''}
			loading={loading}
			{...other}
		/>
	);
};

Image.propTypes = {
	data: PropTypes.object,
	className: PropTypes.string
};

export default Image;
