import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Column } from 'sash-foundation';
import { Image } from '../../..';
import { useCdnQuery } from '../../../utilities/cdn-query';
import { validateArray } from 'sash-foundation/dist/utils';

import './index.scss';

const ProjectSlider = ({ data }) => {
	if (!data || !validateArray(data.tag))
		return null;

	const [projectData, setProjectData] = useState([]);

	useEffect(() => {
		getData();
	}, []);

	const getData = () => {
		const flattenTags = data.tag.map(tag => tag.tag_name);
		const includeQuery = 'include[]=dynamic_tags&include[]=hero.gallery_images&include_count=true';
		const query = `{
			"$and": [
				{
					"dynamic_tags": {
						"$in_query": {
							"tag_name": {
								"$in": ${JSON.stringify(flattenTags)}
							},
							"_content_type_uid": "tag"
						},
						"_content_type_uid": "project_details",
						"sort": {
							"fields": "created_at",
							"order": "DESC"
						}
					}
				}
			]
		}`;

		useCdnQuery("project_details", query, includeQuery, null, 3)
			.then(slideData => {
				if (slideData.data?.entries && validateArray(slideData.data.entries)) {
					let array = slideData.data.entries.filter(node => {
						const tag_name = node.dynamic_tags.map(t => t.tag_name);
						if (flattenTags.every(name => tag_name.includes(name))) {
							return node;
						}
					});

					setProjectData(array);
				}
			})
			.catch((err) => { console.log(err); });
	};

	if (!validateArray(projectData))
		return null;

	return (
		<div className='projectSlider'>
			{data.heading &&
				<div className='sliderHeading'>
					{data.heading}
				</div>
			}

			{validateArray(projectData) &&
				<Row>
					{projectData.map((project, index) => (
						<Column xs={12} sm={6} md={4} key={index}>
							<a href={project.url} className='projectSlider-Link'>
								{project.hero?.gallery_images && validateArray(project.hero.gallery_images) &&
									<Image data={project.hero.gallery_images[0]} />
								}
								
								{project.hero.heading &&
									<div className='projectHeading'>{project.hero.heading}</div>
								}
							</a>
						</Column>
					))}
				</Row>
			}
		</div>
	);
};

ProjectSlider.propTypes = {
	data: PropTypes.object
};

export default ProjectSlider;
