module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.durathermwindow.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","preconnect":["https://fonts.gstatic.com","https://fonts.googleapis.com"],"preload":["https://fonts.googleapis.com/css2?family=Gantari:wght@200;400;600;700&display=swap"],"web":[{"name":"Gantari","file":"https://fonts.googleapis.com/css2?family=Gantari:wght@200;400;600;700&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NT4BDSQ","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Duratherm Window","short_name":"Duratherm","start_url":"/","background_color":"#333333","theme_color":"#dddddd","display":"minimal-ui","icon":"src/images/duratherm_favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b61a0695b4923bd97928c1528ab08144"},
    },{
      plugin: require('../node_modules/gatsby-plugin-accessibilityjs/gatsby-browser.js'),
      options: {"plugins":[],"injectStyles":false,"errorClassName":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
