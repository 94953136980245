import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO({ description, lang = 'en', keywords, title, image, noIndex }) {
	return (
		<StaticQuery
			query={detailsQuery}
			render={data => {
				const metaDescription = description || data.site.siteMetadata.description;

				return (
					<Helmet
						htmlAttributes={{ lang }}
						title={title}
						titleTemplate={`%s | ${data.site.siteMetadata.title}`}
						meta={[
							{
								name: `description`,
								content: metaDescription,
							},
							{
								name: `keywords`,
								content: keywords || ''
							},
							{
								property: `og:title`,
								content: title,
							},
							{
								property: `og:description`,
								content: metaDescription,
							},
							{
								property: `og:type`,
								content: `website`,
							},
							{
								property: `og:image`,
								content: image || ''
							},
							{
								property: `twitter:title`,
								content: title,
							},
							{
								property: `twitter:description`,
								content: metaDescription,
							},
							{
								property: `twitter:type`,
								content: `website`,
							},
							{
								property: `twitter:image`,
								content: image || ''
							},
							{
								property: 'robots',
								content: noIndex ? 'noindex' : ''
							}
						]}
					/>
				);
			}}
		/>
	);
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	keywords: PropTypes.string,
	image: PropTypes.string,
	title: PropTypes.string.isRequired,
	pageType: PropTypes.string,
	swiftype: PropTypes.string,
	noIndex: PropTypes.bool
};

export default SEO;

const detailsQuery = graphql`
{
    site {
      	siteMetadata {
        	title
        	description
        	author
      	}
    }
}
`;
