import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { graphql } from 'gatsby';
import { Container, Row, Column, TextInput, Button, TextArea, Select} from 'sash-foundation';
import { Layout, SEO, BaseDir, GoogleMap, EloquaForm, Image, Breadcrumbs } from '../../components';
import { initDataLayer } from '../../components/utilities/functions';

import { FaCaretRight } from 'react-icons/fa';

import './index.scss';

const Contact = (props) => {
	const data = props.data.contentstackContactUs;
	const [values, setValues] = useState({ firstName: '', lastName: '', emailAddress: '', mobilePhone: '', interest: '', additionalInfo: '' });
	const [careerValues, setCareerValues] = useState({ firstName: '', lastName: '', emailAddress: '', mobilePhone: '', education: '', manuExperience: '' });
	const [success, setSuccess] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [submitDisabled, setSubmitDisabled] = useState(true);

	const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const phoneReplace= /\(|\)|\-|\_| /g;
	const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

	const onSubmit = e => {
		e.preventDefault();
		setSubmitDisabled(true);
		let errorText = '';

		if (values.firstName == '') {
			errorText = 'First Name is a required field';
		} else if (values.lastName == '') {
			errorText = 'Last Name is a required field';
		} else if (values.emailAddress == '') {
			errorText = 'Email Address is a required field';
		} else if (values.mobilePhone == '') {
			errorText = 'Phone is a required field';
		} else if (values.interest == '') {
			errorText = 'Interest is a required field';
		} else if (values.emailAddress.match(emailRegex) === null) {
			errorText = 'Please enter a valid Email Address';
		} else if (values.mobilePhone.replace(phoneReplace).length !== 10) {
			errorText = 'Phone is not a valid phone number';
		} else if (values.mobilePhone.match(phoneRegex) === null) {
			errorText = 'Please enter a valid phone number';
		}

		if (errorText == '') {
			const formData = new FormData(e.currentTarget);

			axios.post('https://tracking.durathermwindow.com/e/f2', formData)
				.then(res => {
					if (res.status === 200) {
						setSuccess(true);
						setSubmitDisabled(true);
						setErrorText('');

						//Data Layer
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({
							'event': 'form-submit',
							'success': 'true',
						});
					}
				})
				.catch(
					// err => console.log(err)
				);
		}

		if (errorText) {
			setSuccess(false);
			setErrorText(errorText);
		}
	};

	const onSubmitCareer = e => {
		e.preventDefault();
		setSubmitDisabled(true);
		let errorText = '';

		if (careerValues.firstName == '') {
			errorText = 'First Name is a required field';
		} else if (careerValues.lastName == '') {
			errorText = 'Last Name is a required field';
		} else if (careerValues.emailAddress == '') {
			errorText = 'Email Address is a required field';
		} else if (careerValues.mobilePhone == '') {
			errorText = 'Phone is a required field';
		} else if (careerValues.education == '') {
			errorText = 'Education is a required field';
		} else if (careerValues.manuExperience == '') {
			errorText = 'Manual Experience is a required field';
		} else if (careerValues.emailAddress.match(emailRegex) === null) {
			errorText = 'Please enter a valid Email Address';
		} else if (careerValues.mobilePhone.replace(phoneReplace).length !== 10) {
			errorText = 'Phone is not a valid phone number';
		} else if (careerValues.mobilePhone.match(phoneRegex) === null) {
			errorText = 'Please enter a valid phone number';
		}

		if (errorText == '') {
			const formData = new FormData(e.currentTarget);

			axios.post('https://tracking.durathermwindow.com/e/f2', formData)
				.then(res => {
					if (res.status === 200) {
						setSuccess(true);
						setSubmitDisabled(true);
						setErrorText('');

						//Data Layer
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({
							'event': 'form-submit',
							'success': 'true',
						});
					}
				})
				.catch(
					// err => console.log(err)
				);
		}

		if (errorText) {
			setSuccess(false);
			setErrorText(errorText);
		}
	};

	useEffect(() => {
		const link = document.getElementById('contactusemail');

		if (link) {
			document.addEventListener('click', dataLayerFunction);
			return () => document.removeEventListener('click', dataLayerFunction);
		}
	}, []);

	const dataLayerFunction = () => {
		initDataLayer('info-email-click',  {infoClick: 'true'});
	};

	return (
		<Layout>
			<SEO {...data.seo} />
			<Container width={1540}>
				{data.url === '/contact/' &&
					<>
						<Row className= 'contact_marginTop'>
							<Column className='' xs='12' lg='5'>
								<h1
									className= 'contact_connectHeading'
									dangerouslySetInnerHTML={{ __html: data.page_content.left.heading}}
								/>
								<div
									className= 'contact_connectInfo'
									dangerouslySetInnerHTML={{ __html: data.page_content.left.subheading}}
								/>
								<div
									className= 'contact_connectInfo'
									dangerouslySetInnerHTML={{ __html: data.page_content.left.description}}
								/>
								<BaseDir data={data.page_content.left.cta[0]} className= 'contact_connect_cta'>
									{data.page_content.left.cta[0].cta_title}
									<FaCaretRight />
								</BaseDir>
								<div className= 'layout_spacer_bot' />
							</Column>
							<Column xs='12' sm='12' md='12' lg='6'>
								<GoogleMap />
							</Column>
						</Row>
						{/* <Row>
							<Column xs='auto'>
								<div
									className= 'contact_featureHeading'
									dangerouslySetInnerHTML={{ __html: data.page_content.left.feature_cta.heading}}
								/>
								<div
									className= 'contact_connectInfo'
									dangerouslySetInnerHTML={{ __html: data.page_content.left.feature_cta.subheading}}
								/>
								<BaseDir data={data.page_content.left.feature_cta.cta[0]} className= 'contact_connect_cta'>
									{data.page_content.left.feature_cta.cta[0].cta_title}
									<FaCaretRight />
								</BaseDir>
							</Column>
						</Row> */}
					</>
				}

				{data.url === '/contact/contact-form/' && success == false &&
					<>
						<Row className= 'contact_marginTop'>
							<Column xs={12} md={10} xl={6}>
								<Row>
									<div
										className= 'contact_connectHeading'
										dangerouslySetInnerHTML={{ __html: data.page_content.right.heading}}
									/>
									<div
										className= 'contact_connectInfo'
										dangerouslySetInnerHTML={{ __html: data.page_content.right.description}}
									/>
								</Row>
								{errorText && <p className='contactForm_Error'>{errorText}</p>}
								<EloquaForm
									elqFormName='ContactUs'
									onFormSubmit={onSubmit}
								>
									<Row
										justify='center'
										noGutters={true}
									>
										<Column xs={12}>
											<label htmlFor="firstName">First Name</label>
											<TextInput
												id='firstName'
												aria-required='true'
												required={true}
												onChange={e => { setValues({ ...values, firstName: e.target.value }); setErrorText(''); setSubmitDisabled(false);}}
												onMouseDown={() => initDataLayer('form-start',  {formStart: 'true'})}
												autocomplete="off"
											/>
										</Column>

										<Column xs={12}>
											<label htmlFor="lastName">Last Name</label>
											<TextInput
												id='lastName'
												aria-required='true'
												required={true}
												onChange={e => { setValues({ ...values, lastName: e.target.value }); setErrorText(''); setSubmitDisabled(false);}}
												autocomplete="off"
											/>
										</Column>
										<Column xs={12}>
											<label htmlFor="emailAddress">Email Address</label>
											<TextInput
												type='email'
												id='emailAddress'
												aria-required='true'
												required={true}
												onChange={e => { setValues({ ...values, emailAddress: e.target.value }); setErrorText(''); setSubmitDisabled(false);}}
												autocomplete="off"
											/>
										</Column>
										<Column xs={12}>
											<label htmlFor="mobilePhone">Phone</label>
											<TextInput
												type='tel'
												id='mobilePhone'
												pattern="[0-9]{10}"
												aria-required='true'
												required={true}
												placeholder='(___) ___-____'
												mask='(999) 999-9999'
												maxlength='10'
												onChange={e => { setValues({ ...values, mobilePhone: e.target.value }); setErrorText(''); setSubmitDisabled(false);}}
												autocomplete="off"
											/>
										</Column>
										<Column xs={12}>
											<label htmlFor="interest">Interest</label>
											<Select
												id='interest'
												aria-required='true'
												required={true}
												onChange={e => { setValues({ ...values, interest: e.target.value }); setErrorText(''); setSubmitDisabled(false);}}
												autocomplete="off"
											>
												<option value=''>--- Please Select ---</option>
												<option value='Quoting a Project'>Quoting a Project</option>
												<option value='Partnering with Duratherm'>Partnering with Duratherm</option>
												<option value='Schedule a Tour'>Schedule a Tour</option>
												<option value='Service'>Service</option>
												<option value='Career Opportunities'>Career Opportunities</option>
											</Select>
										</Column>	
										<Column xs={12}>
											<label htmlFor="additionalInfo">Additional Info</label>
											<TextArea
												id='additionalInfo'
												onChange={e => {setValues({ ...values, additionalInfo: e.target.value }); setErrorText(''); setSubmitDisabled(false);}}
												autocomplete="off"
											/>
										</Column>
										<Column className='contactForm_submit' xs='auto'>
											<Button
												type='submit'
												color='primary'
												gtmLinkType='button'
												gtmLinkText='submit'
												disabled={submitDisabled}
											>
												Submit
											</Button>
										</Column>
									</Row>

									{/* {success && <p className={styles.leadgenForm_Success}>Almost done! Check your inbox to verify your e-mail address.</p>} */}

								</EloquaForm>
							</Column>
							<Column className='sf-display--none sf-display-1290--block contact_emailPic' sm={12} lg={12} xl={6}>
								<Image data={data.page_content.right.image[0]} />
							</Column>
						</Row>
					</>
				}

				{data.url === '/contact/contact-form/' && success == true &&
					<>
						<Row className= 'contact_marginTop'>
							<Column xs='12' xl='6'>
								<Row>
									<div
										className= 'contact_connectHeading'
										dangerouslySetInnerHTML={{ __html: data.success.heading}}
									/>
									<div
										className= 'contact_connectInfo'
										dangerouslySetInnerHTML={{ __html: data.success.description}}
									/>
								</Row>
							</Column>
							<Column className='sf-display--none sf-display-1290--block' lg='6'>
								<Image className='emailPic' data={data.page_content.right.image[0]} />
							</Column>
						</Row>
					</>
				}

				{data.url === '/careers/' && 
				
					<>								
						<Row>
							<Breadcrumbs
								data={data.breadcrumb}
								currentUrl={data.url}
								smallWidth={true}
							/>
						</Row>
						<Row className= 'contact_marginTop2'>		
							<Column className='sf-display--block sf-display-1030--none duartherm_mbot' xs='12'>
								<Image className='' data={data.page_content.right.image[0]} />
							</Column>
							<Column xs='12' lg='6'>
								<Row>
									<div
										className= 'contact_careerHeading'
										dangerouslySetInnerHTML={{ __html: data.page_content.left.heading}}
									/>
									<div
										className= 'contact_careerInfo'
										dangerouslySetInnerHTML={{ __html: data.page_content.left.description}}
									/>
								</Row>
								<Row>
									{errorText && <p className='contactForm_Error'>{errorText}</p>}
									<EloquaForm
										elqFormName='careers'
										onFormSubmit={onSubmitCareer}
									>
										{success !== true &&
											<Row
												justify='center'
												noGutters={true}
											>
												<Column xs={11}>
													<label htmlFor="firstName">First Name</label>
													<TextInput
														id='firstName'
														aria-required='true'
														required={true}
														onChange={e => { setCareerValues({ ...careerValues, firstName: e.target.value }); setErrorText(''); setSubmitDisabled(false);}}
														onMouseDown={() => initDataLayer('form-start',  {formStart: 'true'})}
														autocomplete="off"
													/>
												</Column>

												<Column xs={11}>
													<label htmlFor="lastName">Last Name</label>
													<TextInput
														id='lastName'
														aria-required='true'
														required={true}
														onChange={e => { setCareerValues({ ...careerValues, lastName: e.target.value }); setErrorText(''); setSubmitDisabled(false);}}
														autocomplete="off"
													/>
												</Column>
												<Column xs={11}>
													<label htmlFor="emailAddress">Email Address</label>
													<TextInput
														type='email'
														id='emailAddress'
														aria-required='true'
														required={true}
														onChange={e => { setCareerValues({ ...careerValues, emailAddress: e.target.value }); setErrorText(''); setSubmitDisabled(false);}}
														autocomplete="off"
													/>
												</Column>
												<Column xs={11}>
													<label htmlFor="mobilePhone">Phone</label>
													<TextInput
														type='tel'
														id='mobilePhone'
														pattern="[0-9]{10}"
														aria-required='true'
														required={true}
														placeholder='(___) ___-____'
														mask='(999) 999-9999'
														maxlength='10'
														onChange={e => { setCareerValues({ ...careerValues, mobilePhone: e.target.value }); setErrorText(''); setSubmitDisabled(false);}}
														autocomplete="off"
													/>
												</Column>
												<Column xs={11}>
													<label htmlFor="education">Highest level of education?</label>
													<Select
														id='education'
														aria-required='true'
														required={true}
														onChange={e => { setCareerValues({ ...careerValues, education: e.target.value }); setErrorText(''); setSubmitDisabled(false);}}
														autocomplete="off"
													>
														<option value=''>--- Please Select ---</option>
														<option value='High School'>High School</option>
														<option value='GED'>GED</option>
														<option value='College'>College</option>
														<option value='Masters'>Masters</option>
													</Select>
												</Column>	
												<Column xs={11}>
													<label htmlFor="manuExperience">Do you have prior manufacturing experience?</label>
													<Select
														id='manuExperience'
														aria-required='true'
														required={true}
														onChange={e => { setCareerValues({ ...careerValues, manuExperience: e.target.value }); setErrorText(''); setSubmitDisabled(false);}}
														autocomplete="off"
													>
														<option value=''>--- Please Select ---</option>
														<option value='Yes'>Yes</option>
														<option value='No'>No</option>
													</Select>
												</Column>	
												<Column className='contactForm_submit' xs='auto'>
													<Button
														type='submit'
														color='primary'
														gtmLinkType='button'
														gtmLinkText='submit'
														disabled={submitDisabled}
													>
														Submit
													</Button>
												</Column>
											</Row>
										}
											

									</EloquaForm>
									{success && <p className='leadgenForm_Success'>Application submitted successfully!</p>}

								</Row>
							</Column>
							<Column className='sf-display--none sf-display-1030--block' lg='6'>
								<Row>
									<Column className='sf-display--none sf-display-1030--block duartherm_mbot' xs='12'>
										<Image className='' data={data.page_content.right.image[0]} />
									</Column>
									<Column className='sf-display--none sf-display-1030--block duartherm_mbot' xs='6'>
										<Image className='' data={data.page_content.right.smaller_image[0]} />
									</Column>
									<Column className='sf-display--none sf-display-1030--block duartherm_mbot' xs='6'>
										<Image className='' data={data.page_content.right.smaller_image[1]} />
									</Column>
								</Row>
							</Column>
							<Row justify='center'>
								<Column className='sf-display--block sf-display-1030--none duartherm_mbot' xs='12'>
									<Image className='center' data={data.page_content.right.smaller_image[0]} />
								</Column>
								<Column className='sf-display--block sf-display-1030--none duartherm_mbot' xs='12'>
									<Image className='center' data={data.page_content.right.smaller_image[1]} />
								</Column>
							</Row>



						</Row>
					</>
				}


			</Container>
			<div className='spacer40' />
		</Layout>
	);
};

Contact.propTypes = {
	data: PropTypes.object
};

export default Contact;

export const query = graphql`
	query($uid: String!) {
		contentstackContactUs(uid: { eq: $uid }) {
			hero {
				image {
					...Image
				}
				heading
			}
			breadcrumb {
				active_page
				page_links {
					...CTA
				}
			}
			page_content {
				left {
					heading
					subheading
					description
					cta {
						...CTA
					}
					feature_cta {
						heading
						subheading
						cta {
							...CTA
						}
					}
					form_type
					feature_cta {
						heading
						subheading
						cta {
							...CTA
						}
					}
				}
				right {
					heading
					subheading
					description
					image {
						...Image
					}
					smaller_image {
						...Image
					}
					form_type
				}
			}
			success {
				heading
				description
			}
			url
			title
			seo {
				title
				description
			}
		}
	}

`;
