import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const EloquaForm = (props) => {
	const { elqFormName, elqSiteID = '423468', elqCookieWrite = 0, children, className, onFormSubmit, ...other } = props;

	useEffect(() => {
		() => EloquaGUID();
		var _elqQ = _elqQ || [];
		_elqQ.push(['elqGetCustomerGUID']);
	}, []);

	let timerId = null, timeout = 5;
	const EloquaGUID = () => {
		if (!!(timerId)) {
			if (timeout === 0)
				return;

			if (typeof this.GetElqCustomerGUID === 'function') {
				document.forms[`${elqFormName}`].elements['elqCustomerGUID'].value = GetElqCustomerGUID();
				return;
			}
			timeout -= 1;
		}

		timerId = setTimeout('() => EloquaGUID()', 500);
		return;
	};

	// Grab ELOQUA cookie value, split it and pop it to remove GUID=
	const guid = cookies.get('ELOQUA');
	const guidValue = guid != null ? guid.split('=').pop() : '';

	return (
		<form
			id={elqFormName}
			name={elqFormName}
			className={className || ''}
			onSubmit={onFormSubmit}
			noValidate='off'
			{...other}
		>
			<input type='hidden' name='elqFormName' value={elqFormName} />
			<input type='hidden' name='elqSiteID' value={elqSiteID} />
			<input type='hidden' name='elqCustomerGUID' value={guidValue} />
			<input type='hidden' name='elqCookieWrite' value={elqCookieWrite} />
			<input type='hidden' name='env' value={process.env.GATSBY_CONTENTSTACK_ENVIRONMENT} />

			{children}
		</form>
	);
};

EloquaForm.propTypes = {
	elqFormName: PropTypes.string.isRequired,
	elqSiteID: PropTypes.string,
	elqCookieWrite: PropTypes.number,
	onFormSubmit: PropTypes.func,
	className: PropTypes.string,
	utm: PropTypes.object,
	children: PropTypes.node
};

export default EloquaForm;
