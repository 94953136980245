import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Slider from 'react-slick';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { Container, Row, Column, List } from 'sash-foundation';
import { Layout, SEO, Breadcrumbs, Image, BaseDir, useWindowSize } from '../../components';
import { validateArray, validateLink } from 'sash-foundation/dist/utils';

import './index.scss';

const PrevArrow = (props) => (
	<div className={props.className} style={props.style} onClick={props.onClick}>
		<MdKeyboardArrowUp />
	</div>
);

const NextArrow = (props) => (
	<div className={props.className} style={props.style} onClick={props.onClick}>
		<MdKeyboardArrowDown />
	</div>
);

const ProjectDetails = (props) => {
	const data = props.data.contentstackProjectDetails;
	const sliderRef = useRef(null);
	const thumbRef = useRef(null);
	const [sliderNav, setSliderNav] = useState({ nav1: null, nav2: null });
	const [width, height] = useWindowSize();

	const sliderSettings = {
		dots: false,
		arrows: false,
		infinite: true,
		slidesToScroll: 1,
		slidesToShow: 1,
		asNavFor: sliderNav.nav2,
		ref: slider => (sliderRef.current = slider),
		infinite: false
	};

	const thumbSettings = {
		dots: false,
		arrows: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		vertical: data?.hero?.gallery_images?.length > 3,
		verticalSwiping: true,
		asNavFor: sliderNav.nav1,
		ref: slider => (thumbRef.current = slider),
		focusOnSelect: true,
		infinite: data?.hero?.gallery_images?.length > 3,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			{
				breakpoint: 420,
				settings: { nextArrow: null, prevArrow: null, slidesToShow: 2.5, vertical: false, verticalSwiping: false, infinite: false }
			}
		]
	};

	useEffect(() => {
		setSliderNav({ nav1: sliderRef.current, nav2: thumbRef.current });
	}, []);

	return (
		<Layout>
			<SEO {...data.seo} />

			{data.hero &&
				<div className='projDetails-hero'>
					{data.breadcrumb &&
						<Breadcrumbs
							data={data.breadcrumb}
							currentUrl={data.url}
						/>
					}

					<Container width={1540}>
						<div className='contentSpacing3'>
						<Row align='center'>
							{data.hero.gallery_images && validateArray(data.hero.gallery_images) &&
								<Column xs={12} lg={7}>
									<Slider
										className='projDetails-heroImages'
										{...sliderSettings}
									>
										{data.hero.gallery_images.map((slide, index) => (
											<Image key={index} data={slide} />
										))}
									</Slider>

									<Slider
										className={`projDetails-heroThumbs ${data.hero.gallery_images.length <= 3 ? 'projecDetails-heroThumbs__alt' : ''}`}
										{...thumbSettings}
									>
										{data.hero.gallery_images.map((thumb, index) => (
											<Image key={index} data={thumb} />
										))}
									</Slider>
								</Column>
							}

							<Column xs={12} lg='fluid'>
								<div className='projDetails-heroContent'>
									{data.hero.heading &&
										<h1 className='projDetails-heroHeading'>{data.hero.heading}</h1>
									}

									{data.hero.subheading &&
										<p className='projDetails-heroSubheading'>{data.hero.subheading}</p>
									}

									{data.hero.description &&
										<div
											dangerouslySetInnerHTML={{ __html: data.hero.description }}
											className='projDetails-heroDescription'
										/>
									}

									{data.hero.stats && validateArray(data.hero.stats) &&
										data.hero.stats.map((stat, index) => (
											<p key={index} className='projDetails-heroStat'>{stat}</p>
										))
									}

									{data.hero.products &&
										<div
											className='projDetails-heroProducts'
											dangerouslySetInnerHTML={{ __html: data.hero.products }}
										/>
									}
								</div>
							</Column>
						</Row>
						</div>
					</Container>
				</div>
			}

			{data.page_content && validateArray(data.page_content) && data.page_content.map((block, index) => {
				if (block.content_only != null) {
					return (
						<div key={index} className='projDetails-contentOnly sf-py--xl'>
							<Container width={1540}>
								<Row>
									<Column xs={12} className='projDetails-contentOnlyColumn'>
										{block.content_only.heading &&
											<h2 dangerouslySetInnerHTML={{ __html: block.content_only.heading }} />
										}

										{block.content_only.description &&
											<div
												className='projDetails-contentOnlyDescription'
												dangerouslySetInnerHTML={{ __html: block.content_only.description }}
											/>
										}

										{block.content_only.cta && validateLink(block.content_only.cta) &&
											<BaseDir data={block.content_only.cta[0]} />
										}
									</Column>
								</Row>

								{width >= 768 && index === 0 && data.projects_list?.projects && validateArray(data.projects_list.projects) &&
									<div className='projDetails-asideNav'>
										{data.projects_list.heading &&
											<h3 className='h3__alt'>{data.projects_list.heading}</h3>
										}
										<List bullets='none'>
											{data.projects_list.projects.map((project, index) => (
												<li key={index}>
													<BaseDir data={project} />
												</li>
											))}
										</List>
									</div>
								}
							</Container>
						</div>
					)
				}
			})}

			{width < 768 && data.projects_list?.projects && validateArray(data.projects_list.projects) &&
				<div className='projDetails-asideNav'>
					{data.projects_list.heading &&
						<h3 className='h3__alt'>{data.projects_list.heading}</h3>
					}
					<List bullets='none'>
						{data.projects_list.projects.map((project, index) => (
							<li key={index}>
								<BaseDir data={project} />
							</li>
						))}
					</List>
				</div>
			}
		</Layout>
	);
};

ProjectDetails.propTypes = {
	data: PropTypes.object
};

export default ProjectDetails;

export const query = graphql`
	query($uid: String!) {
		contentstackProjectDetails(uid: { eq: $uid }) {
			url
			breadcrumb {
				active_page
				page_links {
					...CTA
				}
			}
			hero {
				gallery_images {
					...Image
				}
				heading
				subheading
				description
				stats
				products
			}
			projects_list {
				heading
				projects {
					...CTA
				}
			}
			page_content {
				content_only {
					heading
					description
					cta {
						...CTA
					}
				}
			}
			landing_page {
				image {
					...Image
				}
				heading
				description
			}
			seo {
				title
				keywords
				description
			}
		}
	}
`;
