import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { validateArray } from 'sash-foundation/dist/utils';

const CTA = ({ data, className, children, ...other }) => {
	if (!data || (data && data.link && !validateArray(data.link)))
		return null;

	return (
		(data.target !== '_blank' && data.link[0].destination.charAt(0) !== '#') ?
			<Link
				to={data.link[0].destination}
				data-gtm-linktype={data.gtm_link_type || null}
				data-gtm-linktext={data.gtm_link_text || null}
				className={className || ''}
				activeClassName='duratherm-cta--active'
				partiallyActive={true}
				target='_self'
				aria-label={data.aria_label || null}
				title={data.ada || null}
				{...other}
			>
				{children || data.cta_title || data.link[0].default_link_title}
			</Link>
			:
			<a
				href={data.link[0].destination}
				data-gtm-linktype={data.gtm_link_type || null}
				data-gtm-linktext={data.gtm_link_text || null}
				target={data.target}
				rel={data.target === '_blank' ? 'noopener noreferrer' : null}
				className={className || ''}
				aria-label={data.aria_label || null}
				title={data.ada || null}
				{...other}
			>
				{children || data.cta_title || data.link[0].default_link_title}
			</a>
	);
};

CTA.propTypes = {
	data: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node
};

export default CTA;
