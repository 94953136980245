import React, { useEffect, useState } from 'react';
import { navigate, Link } from 'gatsby';
import axios from 'axios';
import { List, Alert } from 'sash-foundation';
import { validateArray } from 'sash-foundation/dist/utils';
import dayjs from 'dayjs';

import { Layout, SEO, LoadingOverlay } from '../../components';
import { isLoggedIn } from '../../components/utilities/private-route';
import { getSessionStorage, logout } from '../../components/utilities/auth';
import './index.scss';

const Forms = () => {
	const userToken = getSessionStorage('__p_app_token__');
	const [loading, setLoading] = useState(true);
	const [forms, setForms] = useState([]);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		if (isLoggedIn())
			fetchFormSubmissions();

		if (!isLoggedIn())
			navigate('/login/');
	}, []);


	function fetchFormSubmissions() {
		axios({
			method: 'GET',
			url: `${process.env.GATSBY_PELLA_API}/duratherm-window-service/api/v1/quotes`,
			headers: {
				Authorization: `Bearer ${userToken}`
			}
		})
			.then(res => {
				if (res.status === 200) {
					setForms(res.data.data);
					setLoading(false);
				}
			})
			.catch(err => {
				if (err) {
					setLoading(false);
					setError('Something went wrong. Please try again later.');
				}
			});
	}

	const onDownloadClick = (filePath) => {
		setLoading(true);
		axios({
			method: 'GET',
			url: `${process.env.GATSBY_PELLA_API}/duratherm-window-service/api/v1/files${filePath}`,
			responseType: 'blob',
			headers: { Authorization: `Bearer ${userToken}` }
		})
			.then(res => {
				if (res.status === 200) {
					let blob = new Blob([res.data]);
					let link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = filePath.split('/')[2];
					link.click();
					setLoading(false);
				}
			})
			.catch(err => {
				if (err) {
					setLoading(false);
					setError('Something went wrong. Please try again later.');
				}
			});
	};

	const onDeleteClick = (id) => {
		axios({
			method: 'DELETE',
			url: `${process.env.GATSBY_PELLA_API}/duratherm-window-service/api/v1/quotes/${id}`,
			headers: {Authorization: `Bearer ${userToken}`}
		}).then(res => {
			if(res.status === 200) {
				fetchFormSubmissions();
				setSuccess(true);							
			}
		}).catch((err) => {
			if(err) {
				setLoading(false);
				setError('Something went wrong. Please try again later.');
			}
		});
	};
	return (
		<Layout>
			<SEO title='Forms' />

			<div className='duratherm-forms-box'>
				<List bullets='none' layout='inline' className='duratherm-loggedin-header'>
					<li><button onClick={() => logout(() => { navigate('/login/'); })}>Logout</button></li>
				</List>

				<h1>Form Submissions</h1>

				{
					loading &&
					<LoadingOverlay fixed={false} />
				}

				{
					error &&
					<Alert type='error' onClose={() => setError('')}>{error}</Alert>
				}
				{
					success && 
						<div className='sf-alert sf-alert--success' role='alert' aria-atomic='true' >
							Success! The submission has been deleted. 
							<button className='sf-alertClose' aria-label='Close' onClick={() => setSuccess(false)} >
								<svg stroke='currentColor' fill='currentColor' strokeWidth='0' viewBox='0 0 24 24' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'></path></svg>
							</button>
						</div>
				}

				{
					forms &&
					validateArray(forms) &&
					<table className='duratherm-table'>
						<thead>
							<tr>
								<th>Customer Name</th>
								<th>Customer Email</th>
								<th>Firm Name</th>
								<th>Project Name</th>
								<th>Project Location</th>
								<th>Date Submitted</th>
								<th>Download</th>
								<th></th>
							</tr>
						</thead>

						<tbody>
							{
								forms.map((form, index) => (
									<tr key={index}>
										<td>{form.name || '-'}</td>
										<td>{form.email || '-'}</td>
										<td>{form.firmName || '-'}</td>
										<td>
											<Link to={`/form-detail/?id=${form._id}`}>
												{form.projectName || '-'}
											</Link>
										</td>
										<td>{form.projectLocation || '-'}</td>
										<td>{dayjs(form.createdAt).format('MM/DD/YYYY')}</td>
										{
											form.filePath &&
											<td>
												<button onClick={() => onDownloadClick(form.filePath)}>
													Download File
												</button>
											</td>
										}
										{
											!form.filePath &&
											<td>-</td>
										}
										<td>
											<button onClick={() => onDeleteClick(form._id)}>Delete</button>
										</td>
									</tr>
								))
							}
						</tbody>
					</table>
				}
			</div>
		</Layout>
	);
};

export default Forms;
