import React from 'react';
import { Layout, SEO } from '../components';
import { Row, Column } from 'sash-foundation';

const NotFoundPage = () => (
	<Layout>
		<SEO title='404 - Page Not Found' />

		<Row className='sf-mt--xl'>
			<Column custom={[ { min: 1, columns: 2.25 } ]} />
			<Column xs={7}>
				<h1>Page Not Found</h1>
				<p>The page you&apos;re looking for does not exist. Try using the navigation to go to a different page or the browsers back button to go back a step.</p>
			</Column>
		</Row>
	</Layout>
);

export default NotFoundPage;
