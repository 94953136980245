import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { MdArrowForward, MdArrowBack } from 'react-icons/md';
import { Container, Row, Column, Modal } from 'sash-foundation';
import { validateArray } from 'sash-foundation/dist/utils';

import { Layout, SEO, Image } from '../../components';
import './index.scss';
import Slider from 'react-slick';

const Portfolio = (props) => {
	const pageData = props.data.contentstackPortfolio;
	const sliderRef = useRef(null);
	const [modal, setModal] = useState('');
	const [activeModal, setActiveModal] = useState(null);
	const [architect, setArchitect] = useState('');
	const [building, setBuilding] = useState('');
	const [region, setRegion] = useState('');
	const [wood, setWood] = useState('');
	const [empty, setEmpty] = useState(false);
	const [recent, setRecent] = useState(false);

	const isValidProj = pageData.projects && validateArray(pageData.projects);
	const architects = isValidProj && [...new Set(pageData.projects.filter(tag => tag.by_architect && validateArray(tag.by_architect) && tag.by_architect[0].tag_name !== '').map(tag => tag.by_architect[0].tag_name))];
	const buildings = isValidProj && [...new Set(pageData.projects.filter(tag => tag.by_building_type && validateArray(tag.by_building_type)).map(tag => tag.by_building_type[0].tag_name))];
	const regions = isValidProj && [...new Set(pageData.projects.filter(tag => tag.by_region && validateArray(tag.by_region)).map(tag => tag.by_region[0].tag_name))];
	const woodSpecies = isValidProj && [...new Set(pageData.projects.filter(tag => tag.by_wood_species && validateArray(tag.by_wood_species)).map(tag => tag.by_wood_species[0].tag_name))];


	useEffect(() => {
		isResultsEmpty();
	}, [architect, building, region, wood]);


	const PrevArrow = () => (
		<button onClick={() => sliderRef.current.slickPrev()} className='duratherm-lightbox-arrow duratherm-lightbox-prev'>
			<MdArrowBack />
		</button>
	);

	const NextArrow = () => (
		<button onClick={() => sliderRef.current.slickNext()} className='duratherm-lightbox-arrow duratherm-lightbox-next'>
			<MdArrowForward />
		</button>
	);

	const onOptionChange = (filter, event) => {
		const filterName =  filter.replace(/\s/g, '_').toLowerCase();
		const filterValue = event.target.value;

		if (recent)
			setRecent(false);

		switch (filterName) {
		case 'by_architect':
			setArchitect(filterValue);
			setBuilding('');
			setRegion('');
			setWood('');
			break;
		case 'by_building_type':
			setArchitect('');
			setBuilding(filterValue);
			break;
		case 'by_region':
			setArchitect('');
			setRegion(filterValue);
			break;
		case 'by_wood_species':
			setArchitect('');
			setWood(filterValue);
			break;
		}
	};

	const onResetClick = () => {
		setArchitect('');
		setBuilding('');
		setRegion('');
		setWood('');
		setRecent(false);
	};

	const isResultsEmpty = () => {
		const results = Array.from(document.getElementsByClassName('duratherm-project-col sf-display--none'));

		if (results.length === pageData.projects.length) {
			setEmpty(true);
		} else {
			setEmpty(false);
		}
	};

	return (
		<Layout>
			<SEO {...pageData.seo} />
			<Container className='spacer150' width={1540}>
				<Row className='sf-mt--xl'>
					{
						pageData.sort_search &&
						<Column xs={12} sm={12} md={12} lg={3}>
						{/* custom={[ { min: 1, columns: 2.25 } ]} */}
							<div className='duratherm-port-box duratherm-port-box--intro'>
								<h1 className='contentH1'>{pageData.sort_search.heading}</h1>
								<p>{pageData.sort_search.description}</p>

								{
									pageData.sort_search.arrow &&
									validateArray(pageData.sort_search.arrow) &&
									<Image data={pageData.sort_search.arrow[0]} />
								}
							</div>

							{
								pageData.sort_search.filter_heading &&
								validateArray(pageData.sort_search.filter_heading) &&
								pageData.sort_search.filter_heading.map((filter, index) => (
									<div key={index} className='duratherm-port-box'>
										<p className='duratherm-port-box-title'>{filter}</p>

										<div className='duratherm-port-box-body'>
											<select
												id={`duratherm-filter-${index}`}
												name={`duratherm-filter-${index}`}
												className='duratherm-filter'
												onChange={e => onOptionChange(filter, e)}
												value={filter === 'BY ARCHITECT' ? architect : filter === 'BY BUILDING TYPE' ? building : filter === 'BY REGION' ? region : filter === 'BY WOOD SPECIES' ? wood : 'All'}
											>
												<option value=''>All</option>
												{
													// Architects
													filter === 'BY ARCHITECT' &&
													architects && validateArray(architects) &&
													architects.map((item, index) => (
														<option value={item} key={index}>{item}</option>
													))
												}

												{
													// Buildings
													filter === 'BY BUILDING TYPE' &&
													buildings && validateArray(buildings) &&
													buildings.map((item, index) => (
														<option value={item} key={index}>{item}</option>
													))
												}

												{
													// By Region
													filter === 'BY REGION' &&
													regions && validateArray(regions) &&
													regions.map((item, index) => (
														<option value={item} key={index}>{item}</option>
													))
												}

												{
													// By Wood Species
													filter === 'BY WOOD SPECIES' &&
													woodSpecies && validateArray(woodSpecies) &&
													woodSpecies.map((item, index) => (
														<option value={item} key={index}>{item}</option>
													))
												}
											</select>
										</div>
									</div>
								))
							}

							<button
								onClick={() => { onResetClick(); setRecent(true); }}
								className={`duratherm-recent-btn ${recent ? 'duratherm-recent-btn--active' : ''}`}
							>
								<Row noGutters={true}>
									<Column xs='fluid'>Recent Work</Column>
									<Column xs='auto'>
										<span>
											<MdArrowForward />
										</span>
									</Column>
								</Row>
							</button>

							<button
								onClick={() => onResetClick()}
								className='duratherm-reset-btn'
							>
								<span>Reset search filters</span>
							</button>
						</Column>
					}

					<Column xs={12} sm={12} md={12} lg={8}>
						{
							empty &&
							<Column xs={12} className='sf-text--center sf-display--flex sf-align-items--center sf-justify-content--center' style={{ height: '100%' }}>
								<h3 className='sf-my--0'>No search results found for these parameters.</h3>
							</Column>
						}

						{
							pageData.projects &&
							validateArray(pageData.projects) &&
							<Row>
								{
									pageData.projects.map((proj, index) => {
										const useFilters = (arc, bld, reg, wood) => {
											if (arc) {
												return proj.by_architect.map(t => t.tag_name).includes(architect) ? '' : 'sf-display--none';
											} else {
												const bldTags = proj.by_building_type.map(t => t.tag_name);
												const regTags = proj.by_region.map(t => t.tag_name);
												const woodTags = proj.by_wood_species.map(t => t.tag_name);
												const tagsArr = [...bldTags, ...regTags, ...woodTags];
												const currentTagsArr = [bld, reg, wood];

												return currentTagsArr.filter(tag => tag !== '').every(name => tagsArr.includes(name)) ? '' : 'sf-display--none';
											}
										};


										return (
											<Column
												key={index} xs={12} sm={6} md={4} lg={3}
												className={`duratherm-project-col sf-mb--lg ${useFilters(architect, building, region, wood)} ${recent && !proj.recent_work ? 'sf-display--none' : ''}`}
											>
												<button className='duratherm-port-btn' onClick={() => { setModal(proj); setActiveModal(index); }}>
													<div className='duratherm-card duratherm-port-card'>
														{proj.thumb_heading && <p className='duratherm-card-header'>{proj.thumb_heading}</p>}

														<div className='duratherm-port-card-body'>
															{
																proj.pop_out &&
																validateArray(proj.pop_out) &&
																proj.pop_out[0].image &&
																validateArray(proj.pop_out[0].image) &&
																<Image data={proj.pop_out[0].image[0]} className='duratherm-card-image' />
															}

															{
																(proj.hover_heading || proj.hover_subhead) &&
																<div className='duratherm-card-hover'>
																	{proj.hover_heading && <h3 className='duratherm-card-title'>{proj.hover_heading}</h3>}
																	{proj.hover_subhead && <p dangerouslySetInnerHTML={{ __html: proj.hover_subhead }} />}
																</div>
															}
														</div>
													</div>
												</button>

												<Modal
													isOpen={activeModal === index}
													onClose={() => { setModal(''); setActiveModal(null); }}
													className='duratherm-lightbox'
													width={800}
												>
													<Slider
														arrows={true}
														dots={false}
														fade={true}
														ref={slider => (sliderRef.current = slider)}
														nextArrow={<NextArrow />}
														prevArrow={<PrevArrow />}
													>
														{
															modal.pop_out &&
															validateArray(modal.pop_out) &&
															modal.pop_out[0].image &&
															validateArray(modal.pop_out[0].image) &&
															modal.pop_out[0].image.map((img, index) => (
																<Image data={img} key={index} />
															))
														}
													</Slider>

													{
														modal.pop_out &&
														validateArray(modal.pop_out) &&
														<div className='duratherm-lightbox-content'>
															<Row>
																<Column xs={8}>
																	{modal.pop_out[0].heading && <h2>{modal.pop_out[0].heading}</h2>}

																	{
																		modal.pop_out[0].description &&
																		<div
																			dangerouslySetInnerHTML={{ __html: modal.pop_out[0].description }}
																			className='duratherm-lightbox-description'
																		/>
																	}
																</Column>

																<Column xs={4}>
																	{
																		modal.pop_out[0].photo_cred &&
																		<p className='duratherm-lightbox-photoCred sf-mb--xl'>{modal.pop_out[0].photo_cred}</p>
																	}

																	{
																		modal.pop_out[0].details &&
																		<p className='duratherm-lightbox-details' dangerouslySetInnerHTML={{ __html: modal.pop_out[0].details }} />
																	}
																</Column>
															</Row>
														</div>
													}
												</Modal>
											</Column>
										);
									})
								}
							</Row>
						}
					</Column>
				</Row>
			</Container>
		</Layout>
	);
};

Portfolio.propTypes = {
	data: PropTypes.object
};

export default Portfolio;

export const query = graphql`
query($uid: String!) {
	contentstackPortfolio(uid: { eq: $uid }) {
		url

		sort_search {
			heading
			description
			arrow {
				...Image
			}
			filter_heading
		}

		projects {
			recent_work
			thumb_heading
			hover_heading
			hover_subhead
			pop_out {
				...ImageWithContent
			}
			by_architect {
				tag_name
			}
			by_building_type {
				tag_name
			}
			by_wood_species {
				tag_name
			}
			by_region {
				tag_name
			}
		}

		seo {
			title
			keywords
			description
		}
	}
}
`;
