import React from 'react';
import PropTypes from 'prop-types';
import InfoWindow from './info-window';
import './index.scss';

const Marker = ({ name, show }) => {
	return (
		<>
			<div
				className="marker"
				style={{ cursor: 'pointer'}}
				title={name}
			/>

			{show && <InfoWindow name={name} />}
		</>
	);
};

Marker.propTypes = {
	name: PropTypes.string,
	show: PropTypes.bool
};

export default Marker;
