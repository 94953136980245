import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import axios from 'axios';

import { List, Button, Alert } from 'sash-foundation';
import { authenticate, loadUser } from '../../components/utilities/auth';
import { isLoggedIn } from '../../components/utilities/private-route';
import { Layout, SEO, LoadingOverlay } from '../../components';
import './index.scss';

const Login = () => {
	const [values, setValues] = useState({});
	const [error, setError] = useState('');
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (isLoggedIn()) {
			navigate('/forms/');
		}
	}, []);

	const onValidateForm = () => {
		let errors = [];

		if (!values.userName) {
			errors.userName = 'Username is required';
		}

		if (!values.password) {
			errors.password = 'Password is required';
		}

		return errors;
	};

	const validateLogin = async () => {
		setLoading(true);

		await axios({
			method: "POST",
			url: `${process.env.GATSBY_PELLA_API}/account/v1.1/auth/login`,
			data: { userName: values.userName, password: values.password, scopes: ['ApiGw.account', 'ApiGw.duratherm-window-service'] },
			headers: { 'Content-Type': 'application/json' }
		})
			.then(res => {
				if (res.data.success) {
					authenticate(res.data.Token, () => {
						loadUser(res.data.Token.access_token, () => {
							setLoading(false);
							navigate('/forms/');
						});
					});
				}

				if (!res.data.success) {
					setError(res.data.error.message);
					setLoading(false);
				}
			})
			.catch(err => {
				if (err) {
					setLoading(false);
					setError('Something went wrong, please try again later.');
				}
			});
	};

	const onFormSubmit = e => {
		e.preventDefault();

		const errors = onValidateForm();

		if (Object.keys(errors).length > 0) {
			setErrors(errors);
		} else {
			validateLogin();
			setLoading(true);
		}
	};

	return (
		<Layout>
			<SEO title='Login' />

			<div className='duratherm-login-wrapper sf-py--2xl sf-my--2xl'>
				<div className='duratherm-login-box'>
					<h1>Duratherm Login</h1>

					{
						error &&
						<Alert type='error' onClose={() => setError('')}>{error}</Alert>
					}

					{
						loading &&
						<LoadingOverlay fixed={false} />
					}

					<form onSubmit={e => onFormSubmit(e)}>
						<List bullets='none'>
							<li>
								<label htmlFor='userName'>Username</label>
								<input
									type='text'
									id='userName'
									name='userName'
									placeholder='Enter your username'
									onChange={e => setValues({ ...values, [e.target.name]: e.target.value })}
								/>

								{errors.userName && <p className='duratherm-error'>{errors.userName}</p>}
							</li>

							<li>
								<label htmlFor='password'>Password</label>
								<input
									type='password'
									id='password'
									name='password'
									onChange={e => setValues({ ...values, [e.target.name]: e.target.value })}
								/>

								{errors.password && <p className='duratherm-error'>{errors.password}</p>}
							</li>

							<li>
								<Button
									type='submit'
									color='primary'
								>
									Login
								</Button>
							</li>
						</List>
					</form>
				</div>
			</div>
		</Layout>
	);
};

Login.propTypes = {
	location: PropTypes.object
};

export default Login;
