import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { MdFileDownload } from 'react-icons/md';
import { Container, Row, Column, List } from 'sash-foundation';
import { Image, Layout, SEO, Breadcrumbs } from '../../components';
import { validateArray, validateImage } from 'sash-foundation/dist/utils';
import ProjectSlider from '../../components/page/pdp/project-slider';

import './index.scss';

const PDP = (props) => {
	const data = props.data.contentstackPdp;
	return (
		<Layout>
			<SEO {...data.seo} />
			<div className='pdp-hero'>
				{data.breadcrumb &&
					<Breadcrumbs
						data={data.breadcrumb}
						currentUrl={data.url}
						smallWidth={true}
					/>
				}

				{data.hero &&
					<Container width={1540}>
						<Row align='center' reverse={data.hero.image_position && data.hero.image_position === 'right'}>
							{data.hero.image && validateImage(data.hero.image) &&
								<Column xs={12} lg={6}>
									<Image
										data={data.hero.image[0]}
										className='pdp-heroImage sf-display--block sf-mb--lg sf-mb-1030--0'
										loading='eager'
									/>
								</Column>
							}

							<Column xs={12} lg='fluid'>
								<Row>
									<Column xs={12} md='fluid'>
										{data.hero.heading &&
											<h1 className='pdp-headingSize'>{data.hero.heading}</h1>
										}
										{data.hero.description &&
											<div
												className='pdp-heroContent'
												dangerouslySetInnerHTML={{ __html: data.hero.description }}
											/>
										}
									</Column>

									{data.hero.downloads?.manual_downloads && validateArray(data.hero.downloads.manual_downloads) &&
										<Column xs={12} md='auto'>
											{data.hero.downloads.heading &&
												<h3 className='yellowText'>{data.hero.downloads.heading}</h3>
											}
											<List bullets='none' className='pdp-heroDownloads'>
												{data.hero.downloads.manual_downloads.map((item, index) => (
													<li key={index}>
														<a
															href={item.file.url}
															title={item.ada_title}
															target={item.target}
															rel='noopener noreferrer'
														>
															<MdFileDownload />
															{item.document_title && <span>{item.document_title}</span>}
														</a>
													</li>
												))}
											</List>
										</Column>
									}
								</Row>
							</Column>
						</Row>
					</Container>
				}
			</div>

			<Container width={1540}>
				{data.modular_blocks && validateArray(data.modular_blocks) && data.modular_blocks.map((block, index) => {
					if (block.data_sheet != null && block.data_sheet.table && validateArray(block.data_sheet.table)) {
						return (
							<div key={index} className='pdp-dataSheetWrapper'>
								{block.data_sheet.heading && <h2 className='h3__alt sf-text--left'>{block.data_sheet.heading}</h2>}

								<div className='pdp-dataSheetColumns'>
									{block.data_sheet.table.map((table, index) => (
										<div key={index} className='pdp-dataSheetColumn'>
											<table className='pdp-dataTable'>
												{table.rows && validateArray(table.rows) &&
													<React.Fragment>
														<thead>
															<tr>
																{table.rows[0].column && validateArray(table.rows[0].column) && table.rows[0].column.map((col, index) => (
																	<th key={index} dangerouslySetInnerHTML={{ __html: col.column_heading }} />
																))}
															</tr>
														</thead>

														<tbody>
															{table.rows.map((row, index) => {
																if (index === 0) return null;

																return (
																	<tr key={index}>
																		{row.column && validateArray(row.column) && row.column.map((col, index) => (
																			<td key={index}>
																				{col.column_heading &&
																					<div dangerouslySetInnerHTML={{ __html: col.column_heading }} />
																				}
																				{col.entry &&
																					<div dangerouslySetInnerHTML={{ __html: col.entry }} />
																				}
																			</td>
																		))}
																	</tr>
																)
															})}
														</tbody>
													</React.Fragment>
												}
											</table>
										</div>
									))}
								</div>
							</div>
						)
					}

					if (block.finishes != null && block.finishes.finish_type && validateArray(block.finishes.finish_type)) {
						return (
							block.finishes.finish_type.map((type, index) => (
								<div key={index} className='pdp-finishWrapper'>
									{type.heading && <h3 className='h3__alt sf-text--left'>{type.heading}</h3>}
									{type.reference && validateArray(type.reference) &&
										<List bullets='none' layout='inline' className='pdp-finishList'>
											{type.reference.map((item, index) => (
												<li key={index} className='sf-text--center'>
													<Image data={item} className='pdp-finishImage' />
													{item.image_title &&
														<p className='pdp-finishTitle' dangerouslySetInnerHTML={{ __html: item.image_title }} />
													}
												</li>
											))}
										</List>
									}
								</div>
							))
						);
					}

					if (block.glazing != null && block.glazing.glazing_types && validateArray(block.glazing.glazing_types)) {
						return (
							<div key={index} className='pdp-glazingWrapper'>
								{block.glazing.heading &&
									<h2 className='sf-text--left sf-mb--sm'>{block.glazing.heading}</h2>
								}

								<Row noGutters={true} className='pdp-glazingRow'>
									{block.glazing.glazing_types.map((col, index) => (
										<Column key={index} xs={12} sm={6} lg={5}>
											<Row className='pdp-glazingRow'>
												{col.heading &&
													<h3 className='h3__alt sf-my--sm'>{col.heading}</h3>
												}
												{col.description &&
													<div className='pdp-glazingContent' dangerouslySetInnerHTML={{ __html: col.description }} />
												}
											</Row>
											<Row className='pdp-glazingRow'>
												{col.image && validateImage(col.image) &&
													<Image data={col.image[0]} className='pdp-glazingImage' />
												}
											</Row>
										</Column>
									))}
								</Row>
							</div>
						);
					}

					if (block.projects != null && block.projects.tag && validateArray(block.projects.tag)) {
						return <ProjectSlider key={index} data={block.projects} />;
					}

					if (block.Content_Only != null) {
						return (
							<div key={index} className='pdp-contentOnly'>
								{block.Content_Only.heading &&
									<h2 className='sf-text--left'>{block.Content_Only.heading}</h2>
								}
								{block.Content_Only.columned_description &&
									<Row>
										{block.Content_Only.columned_description.left &&
											<Column xs={12} md={6}>
												<div dangerouslySetInnerHTML={{ __html: block.Content_Only.columned_description.left }} />
											</Column>
										}
										{block.Content_Only.columned_description.right &&
											<Column xs={12} md={6}>
												<div dangerouslySetInnerHTML={{ __html: block.Content_Only.columned_description.right }} />
											</Column>
										}
									</Row>
								}
							</div>
						);
					}

					if (block.details != null) {
						return (
							<div key={index} className='pdp-details sf-my--2xl'>
								{block.details.key?.heading &&
									<h2 className='sf-mb--md'>{block.details.key.heading}</h2>
								}
								<Row>
									{block.details.key?.image && validateImage(block.details.key.image) &&
										<Column xs={12} sm={6} md={4} lg={3}>
											<div className='pdp-detailImageWrapper sf-mb--lg sf-mb-550--0'>
												<Image
													data={block.details.key.image[0]}
													className='pdp-detailImage sf-display--block'
												/>
											</div>
										</Column>
									}
									{block.details.details?.[0] &&
										<Column xs={12} sm={6} md={8} lg={9}>
											<Row>
												{block.details.details.map((detail, index) => (
													<Column key={index} xs={12} md={6} lg={4} className='pdp-detailColumn'>
														{detail.image && validateImage(detail.image) &&
															<div className='pdp-detailImageWrapper'>
																<Image
																	data={detail.image[0]}
																	className='pdp-detailImage sf-display--block'
																/>
															</div>
														}
														{detail.heading &&
															<p className='pdp-detailHeading sf-text--uppercase sf-mt--md sf-display--flex'>
																<span className='pdp-detailNumber sf-display--inline sf-text--center'>{index + 1}</span>
																<span className='sf-display--inline'>{detail.heading}</span>
															</p>
														}
													</Column>
												))}
											</Row>
										</Column>
									}
								</Row>
							</div>
						);
					}
				})}
			</Container>
		</Layout>
	);
};

PDP.propTypes = {
	data: PropTypes.object
};

export default PDP;

export const query = graphql`
	query($uid: String!) {
		contentstackPdp(uid: { eq: $uid }) {
			url
			breadcrumb {
				active_page
				page_links {
					...CTA
				}
			}
			hero {
				image {
					...Image
				}
				image_position
				heading
				description
				downloads {
					heading
					manual_downloads {
						...TechDoc
					}
				}
			}
			top_content {
				heading
				columned_description {
					left
					right
				}
			}
			modular_blocks {
				finishes {
					finish_type {
						heading
						reference {
							...Image
						}
					}
				}
				glazing {
					heading
					glazing_types {
						image {
							...Image
						}
						heading
						description
					}
				}
				data_sheet {
					heading
					table {
						rows {
							column {
								column_heading
								entry
							}
						}
					}
				}
				projects {
					heading
					tag {
						tag_name
					}
					cta {
						...CTA
					}
				}
				Content_Only {
					heading
					columned_description {
						left
						right
					}
				}
				details {
					key {
						heading
						coordinates
						image {
							...Image
						}
					}
					details {
						heading
						image {
							...Image
						}
					}
				}
			}
			seo {
				title
				keywords
				description
			}
		}
	}
`;
