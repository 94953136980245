import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { MdArrowForward } from 'react-icons/md';
import { validateArray } from 'sash-foundation/dist/utils';

import { Image } from '../../';
import './index.scss';

const Card = ({ title, link, image, heading, description, hideArrow }) => {
	const cardBody = (
		<>
			{
				image &&
				validateArray(image) &&
				<Image data={image[0]} className='duratherm-card-image' />
			}

			{
				(heading || description) &&
				<div className='duratherm-card-body'>
					{heading && <h3 className='duratherm-card-title'>{heading}</h3>}
					{description && <div dangerouslySetInnerHTML={{ __html: description }} className='duratherm-card-description' />}

					{
						!hideArrow &&
						<span className='duratherm-card-arrow'>
							<MdArrowForward />
						</span>
					}
				</div>
			}
		</>
	);

	return (
		<div className='duratherm-card'>
			{title && <p className='duratherm-card-header'>{title}</p>}

			{
				link &&
				link.indexOf('mailto') === -1 &&
				<Link to={link} className='duratherm-card-link'>
					{cardBody}
				</Link>
			}

			{
				link &&
				link.indexOf('mailto') > -1 &&
				<a href={link} target='_blank' rel='noopener noreferrer' title='Link opens in a new window' className='duratherm-card-link'>
					{cardBody}
				</a>
			}

			{
				!link &&
				cardBody
			}
		</div>
	);
};

Card.propTypes = {
	title: PropTypes.string,
	link: PropTypes.string,
	image: PropTypes.array,
	heading: PropTypes.string,
	description: PropTypes.string,
	hideArrow: PropTypes.bool
};

export default Card;
