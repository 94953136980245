// Array validation - pass array in and check for length to be more than 0.
// Use: validateArray(data.array)
export const validateArray = array => Array.isArray(array) && array.length > 0;

// Link validation - pass link in to array validator and check for href existence.
// Use: validateLink(data.link)
export const validateLink = link =>
	validateArray(link) && link[0].link && validateArray(link[0].link) && link[0].link[0].destination;

// Image validation - pass image in to array validator and check for url existence.
// Use: validateImage(data.image)
export const validateImage = image =>
	validateArray(image) && ((image[0].fourk && image[0].fourk.url) || (image[0].desktop && image[0].desktop.url));

// Initialize Datalayer
export const initDataLayer = (event, rest) => {
	if (typeof window !== 'undefined') {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ 'event': event, ...rest });
	}
};