import React from 'react';
import PropTypes from 'prop-types';
import { FaHome } from 'react-icons/fa';
import { Container, List } from 'sash-foundation';
import { validateArray } from 'sash-foundation/dist/utils';

import styles from './index.scss';

const Breadcrumbs = ({ data, currentUrl, smallWidth, className, ...other }) => {
	if (!data || (data && !data.active_page) || !currentUrl) return null;

	return (
		<>
			<div className={`duratherm-breadcrumbs ${className || ''}`} {...other}>
				<Container width={smallWidth == true ? 1540 : 1920}>
					<List
						layout='inline'
						bullets='none'
						className='duratherm-breadcrumbsList'
					>
						<li className='duratherm-iconHome'>
							<a href='/'>
								<FaHome />
								<span className='sf-readerOnly'>Home</span>
							</a>
						</li>

						{validateArray(data.page_links) && data.page_links.map((link, index) => (
								<li key={index}>
									<a
										aria-label={link.aria_label}
										href={link.link[0].destination}
										data-gtm-linktype={link.gtm_link_type}
										data-gtm-linktext={link.gtm_link_text}
										title={link.ada}
									>
										{link.cta_title || link.link[0].default_link_title}
									</a>
								</li>
							))
						}

						{data.active_page &&
							<li>{data.active_page}</li>
						}
					</List>
				</Container>
			</div>
		</>
	);
};

Breadcrumbs.propTypes = {
	data: PropTypes.object,
	currentUrl: PropTypes.string,
	className: PropTypes.string,
	smallWidth: PropTypes.bool,
};

export default Breadcrumbs;
