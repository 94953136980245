import React from 'react';
import PropTypes from 'prop-types';
import { validateArray } from 'sash-foundation/dist/utils';

const BaseDir = ({ data, className, children, iconParams = '', ...other }) => {
	if (!data || (data && !data.link) || (data && data.link && !validateArray(data.link))) return null;

	return (
		<a
			href={data.link[0].destination}
			className={className || null}
			data-gtm-linktype={data.gtm_link_type || null}
			data-gtm-linktext={data.gtm_link_text || null}
			target={data.target || null}
			rel={data.target === '_blank' ? 'noopener noreferrer' : ''}
			aria-label={data.aria || null}
			title={data.ada || null}
			{...other}
		>
			{data.icon && data.icon.url && <img src={data.icon.url + iconParams} />}
			{children || (!data.icon && data.cta_title) || (!data.icon && data.link[0].default_link_title)}
		</a>
	);
};

BaseDir.propTypes = {
	data: PropTypes.object,
	className: PropTypes.string,
	iconParams: PropTypes.string,
	children: PropTypes.node
}

export default BaseDir;
